<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button></app-back-button>
    </ion-buttons>
    <ion-title>
      {{ 'reply.payment.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-payment-input [paymentIntentType] = 1></app-payment-input>
</ion-content>




