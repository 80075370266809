<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button #bb></app-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="ion-padding-bottom ion-padding-end ion-padding-start">
    <h1 class="ion-no-margin">Disclaimer</h1>
    <p class="standard"><small>Last updated: February 22, 2023</small></p>
    <h2>Interpretation and Definitions</h2>
    <h3><a id="a__Interpretation"></a>Interpretation</h3>
    <p class="standard">The words of which the initial letter is capitalized have meanings defined under the following
      conditions. The following definitions shall have the same meaning regardless of whether they appear in singular
      or in plural.</p>
    <h3><a id="a__Definitions"></a>Definitions</h3>
    <p class="standard">For the purposes of this Disclaimer:</p>
    <p class="simple-line"><span class="T2">Company</span> (referred to as either "the Company", "We", "Us" or
      "Our" in this Disclaimer) refers to Eurasia Trade OÜ, Mere pst 4 - 207, Tallinn, Eesti, 10111.</p>
    <p class="simple-line"><span class="T2">Service</span> refers to the Application.</p>
    <p class="simple-line"><span class="T2">You</span> means the individual accessing the Service, or the company,
      or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
    <p class="simple-line"><span class="T2">Application</span> means the software program provided by the Company
      downloaded by You on any electronic device named Pyjam.</p>
    <h2>Disclaimer</h2>
    <p class="standard">The information contained on the Service is for general information purposes only.</p>
    <p class="standard">The Company assumes no responsibility for errors or omissions in the contents of the Service.
    </p>
    <p class="standard">In no event shall the Company be liable for any special, direct, indirect, consequential, or
      incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort,
      arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves
      the right to make additions, deletions, or modifications to the contents on the Service at any time without
      prior notice.</p>
    <p class="standard">The Company does not warrant that the Service is free of viruses or other harmful components.
    </p>
    <h2>Medical Information Disclaimer</h2>
    <p class="standard">The information about health provided by the Service is not intended to diagnose, treat, cure or
      prevent disease. Products, services, information and other content provided by the Service, including
      information linking to third-party websites are provided for informational purposes only.</p>
    <p class="standard">Information offered by the Service is not comprehensive and does not cover all diseases,
      ailments, physical conditions or their treatment.</p>
    <p class="standard">Individuals are different and may react differently to different products. Comments made on the
      Service by employees or other users are strictly their own personal views made in their own personal capacity
      and are not claims made by the Company nor do they represent the position or view of the Company.</p>
    <p class="standard">The Company is not liable for any information provided by the Service with regard to
      recommendations regarding supplements for any health purposes.</p>
    <p class="standard">The Company makes no guarantee or warranty with respect to any products or services sold. The
      Company is not responsible for any damages for information or services provided even if the Company has been
      advised of the possibility of damages.</p>
    <h2>Fitness &amp; Nutritional Information Disclaimer</h2>
    <p class="standard">The Service can offer health, fitness and nutritional information including, without limitation,
      advice and recommendation, that is provided solely as general education and informational purposes.</p>
    <p class="standard">Use of the advice and information contained herein is at your sole choice and risk. You should
      not rely on this information as a substitute for, nor does it replace, professional medical advice, diagnosis,
      or treatment. Always consult your physician or healthcare provider before beginning any nutrition or exercise
      program.</p>
    <p class="standard">If You choose to use this information without prior consent of your physician, You are agreeing
      to accept full responsibility for your decisions and agreeing to hold harmless the Company.</p>
    <h2>FTC Affiliate Disclaimer</h2>
    <p class="standard">The disclosure that follows is intended to fully comply with the Federal Trade Commission's
      policy of the United States that requires the Company to be transparent about any and all affiliate relations
      the Company may have on the Service.</p>
    <p class="standard">You should assume that some of the links are "affiliate links", a link with a special tracking
      code.</p>
    <p class="standard">This means that if You click on an affiliate link and purchase the item, the Company may receive
      an affiliate commission. This is a legitimate way to monetize and pay for the operation of the Service and the
      Company gladly reveal its affiliate relationships to You.</p>
    <p class="standard">The price of the item is the same whether it is an affiliate link or not. Regardless, the
      Company only recommends products or services the Company believes will add value to users.</p>
    <p class="standard">Affiliate advertising programs that the Service uses are:</p>
    <p class="simple-line">Amazon Services LLC Associates Program</p>
    <p class="simple-line">As an Amazon Associate, I earn from qualifying purchases.</p>
    <p class="simple-line">The Company is a participant in the Amazon Services LLC Associates Program, an
      affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and
      linking to Amazon.com or endless.com, MYHABIT.com, SmallParts.com, or AmazonWireless.com.</p>
    <p class="simple-line">Pages on this Service may include affiliate links to Amazon and its affiliate sites on
      which the owner of this Service, Eurasia Trade OÜ, Mere pst 4 - 207, Tallinn, Eesti, 10111, will make a
      referral commission.</p>
    <p class="simple-line">iDevAffiliate</p>
    <p class="simple-line">Commission Junction</p>
    <p class="simple-line">ShareASale</p>
    <p class="simple-line">ClickBank</p>
    <p class="simple-line">ClickFunnels</p>
    <h2>External Links Disclaimer</h2>
    <p class="standard">The Service may contain links to external websites that are not provided or maintained by or in
      any way affiliated with the Company.</p>
    <p class="standard">Please note that the Company does not guarantee the accuracy, relevance, timeliness, or
      completeness of any information on these external websites.</p>
    <h2>Advertising Disclaimer</h2>
    <p class="standard">Third party advertisements and links to third party websites may also appear while using the
      Service. The Company does not make any representation as to the accuracy or suitability of any of the
      information contained in those advertisements or websites and does not accept any responsibility or liability
      for the conduct or content of those advertisements and websites and the offerings made by the third-parties.</p>
    <p class="standard">Third party advertisements and links to other websites where goods or services are advertised
      are not endorsements or recommendations by the Company of the third party sites, goods or services. The Company
      takes no responsibility for the content of the ads, promises made, or the quality/reliability of the products or
      services offered in all advertisements.</p>
    <h2>Product Reviews Disclaimer</h2>
    <p class="standard">In order to monetize and pay for the operation of the Service, the Company may receive
      compensation (either monetarily or through free products) for reviews, or endorsement of a particular company,
      product, or service.</p>
    <p class="standard">The Company remains independent and reviews are done based on the Company's own opinions
      regardless the compensation that can be received.</p>
    <p class="standard">In accordance with the Federal Trade Commission's policy of the United States, articles which
      contain a company, product or service review for which a compensation has been received, will include a
      disclaimer.</p>
    <h2>Errors and Omissions Disclaimer</h2>
    <p class="standard">The information given by the Service is for general guidance on matters of interest only. Even
      if the Company takes every precaution to insure that the content of the Service is both current and accurate,
      errors can occur. Plus, given the changing nature of laws, rules and regulations, there may be delays, omissions
      or inaccuracies in the information contained on the Service.</p>
    <p class="standard">The Company is not responsible for any errors or omissions, or for the results obtained from the
      use of this information.</p>
    <h2>Fair Use Disclaimer</h2>
    <p class="standard">The Company may use copyrighted material which has not always been specifically authorized by
      the copyright owner. The Company is making such material available for criticism, comment, news reporting,
      teaching, scholarship, or research.</p>
    <p class="standard">The Company believes this constitutes a "fair use" of any such copyrighted material as provided
      for in section 107 of the United States Copyright law.</p>
    <p class="standard">If You wish to use copyrighted material from the Service for your own purposes that go beyond
      fair use, You must obtain permission from the copyright owner.</p>
    <h2>Views Expressed Disclaimer</h2>
    <p class="standard">The Service may contain views and opinions which are those of the authors and do not necessarily
      reflect the official policy or position of any other author, agency, organization, employer or company,
      including the Company.</p>
    <p class="standard">Comments published by users are their sole responsibility and the users will take full
      responsibility, liability and blame for any libel or litigation that results from something written in or as a
      direct result of something written in a comment. The Company is not liable for any comment published by users
      and reserves the right to delete any comment for any reason whatsoever.</p>
    <h2>No Responsibility Disclaimer</h2>
    <p class="standard">The information on the Service is provided with the understanding that the Company is not herein
      engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not
      be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers.
    </p>
    <p class="standard">In no event shall the Company or its suppliers be liable for any special, incidental, indirect,
      or consequential damages whatsoever arising out of or in connection with your access or use or inability to
      access or use the Service.</p>
    <h2>"Use at Your Own Risk" Disclaimer</h2>
    <p class="standard">All information in the Service is provided "as is", with no guarantee of completeness, accuracy,
      timeliness or of the results obtained from the use of this information, and without warranty of any kind,
      express or implied, including, but not limited to warranties of performance, merchantability and fitness for a
      particular purpose.</p>
    <p class="standard">The Company will not be liable to You or anyone else for any decision made or action taken in
      reliance on the information given by the Service or for any consequential, special or similar damages, even if
      advised of the possibility of such damages.</p>
    <h2>Contact Us</h2>
    <p class="standard">If you have any questions about this Disclaimer, You can contact Us:</p>
    <ul>
      <li>
        <p class="P2" style="margin-left:0cm;">
          By email: <strong>support@pyjam.com</strong></p>
      </li>
    </ul>
  </div>
</ion-content>
