import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { SubscriptionsBag } from './subscriptions-bag';

export class ModalComponentBase {
  private dataToDismiss = null;
  private dismissed: boolean = false;
  private sb: SubscriptionsBag = new SubscriptionsBag();
  private static modalLevel: number = 0;
  private myModalLevel: number;

  constructor(
    protected modalController: ModalController,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected location: Location,
  ) {
  }

  public onInit(): void {
  }

  public async init(): Promise<void> {
    if (this.myModalLevel > 0) {
      return;
    }

    this.myModalLevel = ++ModalComponentBase.modalLevel;

    return this.router.navigate([], {
      queryParams: {modalLevel: this.myModalLevel},
      queryParamsHandling: 'merge'
    })
      .then(() => {
        this.sb.sub = this.activatedRoute.queryParamMap
          .pipe(
            map(qp => parseInt(qp.get('modalLevel')) || 0),
            distinctUntilChanged()
          )
          .subscribe(modalLevel => {
            ModalComponentBase.modalLevel = modalLevel;

            if ((!modalLevel || modalLevel < this.myModalLevel) && !this.dismissed) {
              this.dismissed = true;
              this.modalController.dismiss(this.dataToDismiss).then();
              this.sb.unsubscribeAll();
            }
          });
      });
  }

  public ionViewWillEnter(): void {
    this.init().then();
  }

  public onDestroy(): void {
    this.sb.unsubscribeAll();
  }

  public async dismiss(data: any = undefined): Promise<void> {
    this.dataToDismiss = data;

    if (this.location && window.location.pathname !== '/login/sign-in') {
      this.location.back();
    } else {
      await this.router.navigate([], {
        queryParams: {modalLevel: this.myModalLevel - 1 || undefined},
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }

  }
}
