<ion-datetime #datePicker
              locale="ru"
              style="user-select: none; cursor: pointer; height: 100%"
              [presentation]="showTime ? 'date-time' : 'date'"
              first-day-of-week="1"
              [showDefaultTimeLabel]="false"
              [value]="value"
              [min]="minDate"
              [max]="maxDate"
              [minuteValues]="minuteValues ?? [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
              [hourValues]="hourValues ?? undefined">
  <ion-buttons slot="buttons">
    <ion-button color="danger" (click)="onCancelClick()"> {{ 'chat.buttons.cancel' | translate }}</ion-button>
    <ion-button color="primary" (click)="onSubmitClick()"> {{ 'chat.buttons.ok' | translate }}</ion-button>
  </ion-buttons>
</ion-datetime>
