<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button #bb></app-back-button>
    </ion-buttons>
    <ion-title>
      {{ 'reply.dispute.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-bottom ion-padding-start ion-padding-end">
  <div *ngIf="!isAcceptLicenseAgreement">
    <div *ngFor="let paragraph of ('reply.dispute.content' | translate)" [innerHTML]="paragraph || '&nbsp;'"></div>
    <div class="checkbox">
      <ion-checkbox color="dark" (ionChange)="agreementCheckboxChange($event)">
      </ion-checkbox>
      <ion-label>
        {{ 'reply.dispute.agreementCheckbox' | translate }}
      </ion-label>
    </div>
  </div>
  <form *ngIf="isAcceptLicenseAgreement && form" [formGroup]="form" class="custom-form">
    <h2 class="title">{{ 'reply.dispute.description' | translate }}</h2>
    <div class="field">
      <ion-textarea #descriptionControl
                    formControlName = "description"
                    class = "custom-ion-textarea"
                    name = "textarea"
                    placeholder = "{{ 'reply.dispute.description' | translate }}"
                    rows = "10"
                    maxlength = "1500">
      </ion-textarea>
      <div *ngIf="form.touched && form.controls.description.errors?.required" class="error">
        {{ 'reply.dispute.requiredField' | translate }}
      </div>
    </div>
  </form>
</ion-content>

<ion-footer *ngIf="!isAcceptLicenseAgreement" class="ion-no-border">
  <ion-button class="ion-margin button-bottom"
              color="dark" expand="block"
              [appLoaderInButton]="onAcceptAgreementClick.bind(this)"
              [disabled]="!isCheckedLicenseAgreement">
    {{ 'reply.dispute.continue' | translate }}
  </ion-button>
</ion-footer>

<ion-footer *ngIf="isAcceptLicenseAgreement && form" class="ion-no-border">
  <ion-button class="ion-margin button-bottom"
              color="dark" expand="block"
              [appLoaderInButton]="onReplyDisputeClick.bind(this)"
              [disabled]="form?.invalid">
    {{ 'reply.dispute.continue' | translate }}
  </ion-button>
</ion-footer>
