import {Injectable} from "@angular/core";
import {
  ReportContentPopupComponent,
  ReportType
} from "../ui-components/report-content-popup/report-content-popup.component";
import {ModalController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(
    private modalController: ModalController,
  ) {}

  public async openReportModal(reportType: ReportType, reportedObjectId: number): Promise<void> {
    const modal = await this.modalController.create({
      component: ReportContentPopupComponent,
      componentProps: {
        reportType: reportType,
        reportedObjectId: reportedObjectId,
      },
    });

    await modal.present();
  }

}
