import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AppStateChangeService {
  private appStateChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private resumeSubject: BehaviorSubject<true> = new BehaviorSubject(null);

  constructor(private ngZone: NgZone,
              private platform: Platform,
  ) {
    // Срабатывает только при возобновлении приложения из фона.
    // Специфичен для платформы Cordova.
    this.platform.resume.subscribe((): void => {
      this.emitResume(true);
    });

    // Работает с любыми изменениями состояния приложения (активное или неактивное)
    // Является более универсальным и поддерживает платформы iOS и Android через Capacitor.
    App.addListener('appStateChange', async ({isActive}): Promise<void> => {
      await this.ngZone.run(async (): Promise<void> => {
        this.emitAppState(isActive);
      });
    });
  }

  public get resume$(): Observable<boolean> {
    return this.resumeSubject;
  }

  public get appStateChange$(): Observable<boolean> {
    return this.appStateChangeSubject;
  }

  public emitResume(isActive: true): void {
    // console.log('\x1b[95m' + 'App resume is: ' + isActive + '\x1b[0m');
    this.resumeSubject.next(isActive);
  }

  public emitAppState(isActive: boolean): void {
    // console.log('\x1b[95m' + 'App state is active: ' + isActive + '\x1b[0m');
    this.appStateChangeSubject.next(isActive);
  }
}
