<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button #bb></app-back-button>
    </ion-buttons>
    <ion-title>
      {{ 'reply.extraWork.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-bottom ion-padding-start ion-padding-end">
  <form *ngIf="form" [formGroup]="form" class="custom-form">
    <h2 class="title">{{ 'reply.extraWork.description' | translate }}</h2>
    <div class="field">
      <ion-textarea #descriptionControl
                    formControlName="description"
                    class="custom-ion-textarea"
                    name="textarea"
                    placeholder="{{ 'reply.extraWork.description' | translate }}"
                    rows="6"
                    maxlength="1500">
      </ion-textarea>
      <div *ngIf="form.touched && form.controls.description.errors?.required" class="error">
        {{ 'reply.extraWork.requiredField' | translate }}
      </div>
    </div>
<!--    <app-image-adding [files]="files"-->
<!--                      (filesUpdated)="onFilesUploaded($event)"-->
<!--                      (fileDeleted)="onDeleteFile($event)">-->
<!--    </app-image-adding>-->
    <!--<div class="field check" *ngIf="isFreeExtraWork">
      <ion-toggle #priceCheckControl
                  formControlName="checkValuePrice"
                  slot="start"
                  (ionChange)="checkValuePriceChanged($event)">
      </ion-toggle>
      <ion-label>{{ 'reply.extraWork.additionalPayment' | translate }}</ion-label>
    </div>
    <div class="field price" (click)="$event.stopImmediatePropagation()">
      <ion-input #priceControl
                 type="number"
                 maxlength="6"
                 inputMode="number"
                 formControlName="price"
                 class="custom-ion-input"
                 placeholder="Сумма"
                 (keydown)="onInputKeyDown($event)">
      </ion-input>
      <div *ngIf="form.touched && !form.controls.price.value && form.controls.checkValuePrice.value" class="error">
        {{ 'reply.extraWork.requiredField' | translate }}
      </div>
      <div *ngIf="form.touched && form.controls.price.errors?.min" class="error">
        {{ 'reply.extraWork.min' | translate }}
      </div>
      <div *ngIf="form.touched && form.controls.price.errors?.max" class="error">
        {{ 'reply.extraWork.max' | translate }}
      </div>
    </div>-->
  </form>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-button class="ion-margin button-bottom"
              color="dark" expand="block"
              [appLoaderInButton]="onReplyExtraWorkClick.bind(this)"
              [disabled]="form?.invalid">
    {{ 'reply.extraWork.continue' | translate }}
  </ion-button>
</ion-footer>
