import { Component, OnInit } from '@angular/core';
import { ExtendedClient } from '../../api-clients/pyjam/extended-client';
import { LoadingService } from '../../services/loading.service';
import { ToastService } from '../../services/toast.service';
import { NotificationResponse, NotificationsDataResponse } from '../../api-clients/pyjam/client';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ChatController } from 'src/app/chat/chat.controller';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  public notifications: NotificationResponse [] = [];
  public lastLoadedPage: number = 1;
  public countOnPage: number = 20;

  constructor(
    private extendedClient: ExtendedClient,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private router: Router,
    private appComponent: AppComponent,
    private chatController: ChatController,
    private translate: TranslateService,
    public authService: AuthService,
  ) {
  }

  ngOnInit() {
    if (this.authService.isAuthenticated) {
      this.loadNotificationData(1).then();
    }

    this.chatController.notificationEvent.subscribe(res => {
      switch (res.type) {
        case 'notification': {
          this.notifications.unshift(res.data);
          break;
        }
      }
    });
  }

  public async loadNotificationData(page): Promise<NotificationsDataResponse | void> {
    return this.loadNotifications(page)
      .then((notifications) => {
        if (notifications.data.length > 0) {
          this.lastLoadedPage = 1;
        }
        this.notifications = [...notifications.data];
        this.read();
      });
  }

  public loadNotifications(page: number): Promise<NotificationsDataResponse> {
    return this.getNotifications(page) as Promise<NotificationsDataResponse>;
  }

  public async getNotifications(page: number): Promise<NotificationsDataResponse | void> {
    await this.loadingService.start();
    return await this.extendedClient.notificationIndex(page).toPromise()
      .then((result: NotificationsDataResponse) => result)
      .catch(async ex => {
        // await this.httpErrorHandlerService.handleHttpException(ex);
      }).finally(() => this.loadingService.stop());
  }

  onScrollDown() {
    this.loadNotifications(this.lastLoadedPage + 1)
      .then((notifications) => {
        if (notifications.data.length > 0) {
          this.lastLoadedPage++;
          this.notifications.push(...notifications.data);
          this.read();
        }
      });
  }

  public async read(): Promise<void> {
    if (this.notifications.filter(v => !v.is_read).length == 0) {
      return;
    }
    await this.loadingService.start();
    await this.extendedClient.notificationRead(this.notifications.filter(v => !v.is_read).map(v => v.id)).toPromise()
      .then(() => {
        this.appComponent.countNotifications = 0;
        this.loadingService.stop();
      })
      .catch((err) => {
        this.translate.get('notifications.error', {value: err}).subscribe((t) => {
          this.toastService.error(t);
        });
        this.loadingService.stop().then();
      });
  }

  public async delete(event: Event, id: number, i: number): Promise<void> {
    event.stopPropagation();
    await this.loadingService.start();
    await this.extendedClient.notificationDelete(id).toPromise()
      .then(() => {
        this.notifications.splice(i, 1);
        this.loadingService.stop();
      })
      .catch((err) => {
        this.translate.get('notifications.error', {value: err}).subscribe((t) => {
          this.toastService.error(t);
        });
        this.loadingService.stop().then();
      });
  }

  check(notification) {
    switch (notification.type) {
      case 1:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 2:
        if (notification.body?.taskId) {
          this.router.navigate(['tasks', notification.body?.taskId, 'comments']);
        }
        break;
      case 3:
        if (notification.body?.replyId) {
          this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case 4:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 5:
        if (notification.body?.replyId) {
          this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case 6:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 7:
        if (notification.body?.taskId) {
          this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case 8:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 9:
        if (notification.body?.paymentId) {
          this.router.navigate(['profile/balance']);
        }
        break;
      case 10:
        // if (notification.body?.onboardingId) {
        //   this.router.navigate(['profile/balance']);
        // }
        break;
      case 11:
        if (notification.body?.replyId) {
          this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case 12:
        if (notification.body?.replyId) {
          this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case 13:
        if (notification.body?.replyId) {
          this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case 14:
        if (notification.body?.replyId) {
          this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case 15:
        if (notification.body?.taskId) {
          this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case 16:
        if (notification.body?.taskId) {
          this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case 17:
        if (notification.body?.replyId) {
          this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case 18:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 19:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 20:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 21:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 22:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 23:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 24:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 25:
        if (notification.body?.replyId) {
          this.router.navigate(['reply', notification.body?.replyId]);
        }
        break;
      case 26:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 27:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 28:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 29:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 30:
        if (notification.body?.categoryId) {
          this.router.navigate(['tasks/add/category']);
        }
        break;
      case 31:
        if (notification.body?.paymentId) {
          this.router.navigate(['profile/balance']);
        }
        break;
      case 32:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 33:
        if (notification.body?.chatId) {
          this.router.navigate(['chats', notification.body?.chatId]);
        }
        break;
      case 34:
        if (notification.body?.taskId) {
          this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case 35:
        if (notification.body?.taskId) {
          this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case 36:
        if (notification.body?.taskId) {
          this.router.navigate(['tasks', notification.body?.taskId]);
        }
        break;
      case 37:
        if (notification.body?.avatarOrderId) {
          this.router.navigate(['avatar-orders', notification.body?.avatarOrderId]);
        }
        break;
    }
  }

  logInClick() {
    window.sessionStorage.setItem('noAuthBtn', '1');
    window.sessionStorage.removeItem('showMenu');
    this.router.navigateByUrl('/login/sign-in').then(() => true);
  }
}