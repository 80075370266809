<ng-container *ngIf="stars !== null">
  <span [ngStyle]="{'width': width ? width + 'px' : 'auto'}">
  <ion-icon
    color="dark"
    *ngFor="let star of [1,2,3,4,5]"
    [src]="star > stars
    ? 'assets/icon/star-grey.svg'
    : 'assets/icon/star-white.svg'">
  </ion-icon>
</span>
</ng-container>


