import {Injectable} from '@angular/core';
// import {ActivatedRoute, Router} from "@angular/router";
// import {ToastService} from "./toast.service";
import {ApiException} from "../api-clients/pyjam/client";
// import {environment} from "../../environments/environment";
// import {TranslateService} from "@ngx-translate/core";

// Для удобства тестирования и отладки выводит тоаст с http ошибкой на development билдах

@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlerService {

  public constructor(
    // private toastService: ToastService,
    // private translateService: TranslateService
  ) {
  }

  public async handleHttpException(apiException: ApiException): Promise<void> {
    // if (!environment.production) {
    //   let parsedException = JSON.parse(apiException.response);
    //   let message = parsedException.message ?? parsedException.error;
    //   await this.toastService.error(message);
    // }
  }

}

