import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NotificationsComponent} from "./notifications/notifications.component";
import {NotificationRoutingModule} from "./notification-routing.module";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    NotificationsComponent
  ],
  imports: [
    CommonModule,
    NotificationRoutingModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    TranslateModule,
  ]
})
export class NotificationModule {
}
