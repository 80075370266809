import { Injectable, } from '@angular/core';
import { BehaviorSubject, catchError, delay, exhaustMap, filter, from, of, skip, Subject, tap } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { TokenStoreService } from '../auth/token-store.service';
import { AuthService, ParsedToken, ParsedUser } from '../auth/auth.service';
import { AppStateChangeService } from '../services/app-state-change.service';
import {
  EmitMessage,
  PhoneInfoRequest,
  PhoneInfoRequestExt,
  PhoneInfoResponse,
  PhoneInfoResponseExt,
  ReadDto
} from './chat.interfaces';
import { SocketError } from '../interfaces';
import { distinctUntilChanged } from 'rxjs/operators';
import { PlatformService } from '../services/platform.service';
import { SubscriptionsBag } from '../services/subscriptions-bag';
import { OnlineStateService } from '../services/online-state.service';

@Injectable({
  providedIn: 'root'
})
export class ChatController {
  public chatEvent: BehaviorSubject<any> = new BehaviorSubject('');
  public notificationEvent: BehaviorSubject<any> = new BehaviorSubject('');
  public avatarEvent: Subject<any> = new Subject();
  public fileUploadEvent: Subject<any> = new Subject();
  public socketConnectedAndInitedEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private socketConnectedButNotInited: boolean = false;
  private err$: Subject<SocketError> = new Subject();
  private sb: SubscriptionsBag = new SubscriptionsBag();

  constructor(
    private socket: Socket,
    private tokenStoreService: TokenStoreService,
    private authService: AuthService,
    private appStateChangeService: AppStateChangeService,
    private platformService: PlatformService,
    private onlineStateService: OnlineStateService,
  ) {
  }

  ngOnDestroy(): void {
    this.sb.unsubscribeAll();
    this.err$?.unsubscribe();
  }

  public async init(): Promise<void> {
    this.subscribeToSocketEvents();

    this.sb.sub = this.authService.parsedUser$.pipe(
      distinctUntilChanged(),
      tap((parsedUser: ParsedUser): void => {
        if (parsedUser) {
          if (!this.socket.ioSocket.connected) this.socket.connect();
        } else {
          if (this.socket.ioSocket.connected) this.socket.disconnect();
        }
      })
    ).subscribe();

    this.sb.sub = this.authService.parsedToken$.pipe(
      distinctUntilChanged(),
      filter((parsedToken: ParsedToken): boolean => !!parsedToken),
      skip(1),
      tap(async (): Promise<void> => {
        await this.initJwtToken();
      })
    ).subscribe();

    this.sb.sub = this.appStateChangeService.appStateChange$.pipe(
      distinctUntilChanged(),
      filter((isActive: boolean): boolean => isActive),
      tap((): void => {
        if (this.platformService.isDevice) {
          this.socketConnect();
        }
      })
    ).subscribe();
  }

  //#region Socket Emits

  public emitAnySocketEvent(eventName: string, eventData: any): void {
    this.validateEmitData({...eventData}, eventName);
    // console.log('\x1b[35m' + `Socket emit '${eventName}'` + '\x1b[0m', eventData);
    this.socket.emit(eventName, eventData);
  }

  public emitInit(jwtToken: string): void {
    this.validateEmitData({jwtToken}, 'init');
    // console.log('\x1b[35m' + `Socket emit 'init'` + '\x1b[0m', jwtToken);
    this.socket.emit('init', jwtToken);
  }

  public emitSendMessage(data: EmitMessage): void {
    this.validateEmitData(data, 'message');
    // console.log('\x1b[35m' + `Socket emit 'message'` + '\x1b[0m', data);
    this.socket.emit('message', data);
  }

  public emitGetHistory(message_id: string, to_id: number, count: number, chat_id: number, skip: number = 0): void {
    const data = {
      message_id,
      to_id,
      count,
      chat_id,
      skip
    };
    this.validateEmitData(data, 'getHistory');
    // console.log('\x1b[35m' + `Socket emit 'getHistory'` + '\x1b[0m', data);
    this.socket.emit('getHistory', data);
  }

  public emitRead(data: ReadDto): void {
    this.validateEmitData(data, 'read');
    // console.log('\x1b[35m' + `Socket emit 'read'` + '\x1b[0m', data); read: ', data);
    this.socket.emit('read', data);
  }

  public emitAvatarCustomerReady(to_id: number, order_id: number): void {
    const data = {to_id, order_id};
    this.validateEmitData(data, 'avatarCustomerReady');
    // console.log('\x1b[35m' + `Socket emit 'avatarCustomerReady'` + '\x1b[0m', data);
    this.socket.emit('avatarCustomerReady', data);
  }

  public emitAvatarMessage(user_id: number, command: string): void {
    const data = {user_id, message: command};
    this.validateEmitData(data, 'avatarMessage');
    // console.log('\x1b[35m' + `Socket emit 'avatarMessage'` + '\x1b[0m', data);
    this.socket.emit('avatarMessage', data);
  }

  public emitAvatarOffer(customer_id: number, order_id: number, offer: RTCSessionDescriptionInit): void {
    const data = {user_id: customer_id, order_id, offer};
    this.validateEmitData(data, 'avatarOffer');
    // console.log('\x1b[35m' + `Socket emit 'avatarOffer'` + '\x1b[0m', data);
    this.socket.emit('avatarOffer', data);
  }

  public emitAvatarAnswer(executor_id: number, answer: RTCSessionDescriptionInit): void {
    const data = {user_id: executor_id, answer};
    this.validateEmitData(data, 'avatarAnswer');
    // console.log('\x1b[35m' + `Socket emit 'avatarAnswer'` + '\x1b[0m', data);
    this.socket.emit('avatarAnswer', data);
  }

  public emitAvatarCandidate(user_id: number, candidate: RTCIceCandidateInit): void {
    const data = {user_id, candidate};
    this.validateEmitData(data, 'avatarCandidate');
    // console.log('\x1b[35m' + `Socket emit 'avatarCandidate'` + '\x1b[0m', data);
    this.socket.emit('avatarCandidate', data);
  }

  public emitAvatarExit(user_id: number): void {
    const data = {user_id};
    this.validateEmitData(data, 'avatarExit');
    // console.log('\x1b[35m' + `Socket emit 'avatarExit'` + '\x1b[0m', data);
    this.socket.emit('avatarExit', data);
  }

  public emitAvatarPhoneInfoRequest(to_id: number): void {
    const data: PhoneInfoRequest = {to_id};
    this.validateEmitData(data, 'avatarPhoneInfoRequest');
    // console.log('\x1b[35m' + `Socket emit 'avatarPhoneInfoRequest'` + '\x1b[0m', data);
    this.socket.emit('avatarPhoneInfoRequest', data);
  }

  public emitAvatarPhoneInfoResponse(to_id, battery_level: number, is_charging: boolean, phone_model: string): void {
    const data: PhoneInfoResponse = {to_id, battery_level, is_charging, phone_model};
    this.validateEmitData(data, 'avatarPhoneInfoResponse');
    // console.log('\x1b[35m' + `Socket emit 'avatarPhoneInfoResponse'` + '\x1b[0m', data);
    this.socket.emit('avatarPhoneInfoResponse', data);
  }

  public emitAvatarSyncStatus(is_online: boolean, turn_off: boolean): void {
    const data = {is_online, turn_off};
    this.validateEmitData(data, 'avatarSyncStatus');
    // console.log('\x1b[35m' + `Socket emit 'avatarSyncStatus'` + '\x1b[0m', data);
    this.socket.emit('avatarSyncStatus', data);
  }

  public emitAvatarRequestReconnect(to_id: number, order_id: number): void {
    const data = {to_id, order_id};
    this.validateEmitData(data, 'avatarRequestReconnect');
    // console.log('\x1b[35m' + `Socket emit 'avatarRequestReconnect'` + '\x1b[0m', data);
    this.socket.emit('avatarRequestReconnect', data);
  }

  public emitAvatarCustomerReadyReconnect(to_id: number, ready_reconnect: boolean, order_id: number): void {
    const data = {to_id, ready_reconnect, order_id};
    this.validateEmitData(data, 'avatarCustomerReadyReconnect');
    // console.log('\x1b[35m' + `Socket emit 'avatarCustomerReadyReconnect'` + '\x1b[0m', data);
    this.socket.emit('avatarCustomerReadyReconnect', data);
  }

  public emitAvatarZoom(user_id: number, zoom: number): void {
    const data = {user_id, zoom: zoom};
    this.validateEmitData(data, 'avatarZoom');
    this.socket.emit('avatarZoom', data);
  }

  //#endregion Socket Emits

  private async initJwtToken(): Promise<void> {
    if (this.authService.isAuthenticated) {
      let jwtToken = await this.tokenStoreService.getToken();
      this.emitInit(jwtToken);
    }
  }

  private async refreshToken(): Promise<void> {
    return this.authService.refreshToken();
  }

  private socketConnect(): void {
    if (!this.socket.ioSocket.connected) this.socket.connect();
  }

  private subscribeToSocketEvents(): void {
    this.err$.pipe(
      filter((error: SocketError) => error?.message === 'Unauthenticated.' || error?.message === 'invalid credentials'),
      exhaustMap(() =>
        from(this.refreshToken()).pipe(
          catchError(error => {
            return of(error);
          })
        )
      ),
      delay(1500),
      tap((error: SocketError): void => {
        if (error?.lastMethod !== 'init' && error?.lastData) {
          this.emitAnySocketEvent(error.lastMethod, error.lastData);
        }
      })
    ).subscribe();

    //logEvents

    this.socket.on('err', (data: any): void => {
      this.err$.next(data);
      console.error('Socket err event! ', data);
      // if (data && data.message && data.message != 'avatar_order.broadcast_is_started') {
      //   this.toastService.error(data.message);
      // }
    });

    this.socket.on('log', (data: any): void => {
      console.warn('Socket log event! ', data);
    });

    //connectEvents

    this.socket.on('connect', async (): Promise<void> => {
      console.log('\x1b[32m' + 'Socket is connected' + '\x1b[0m');
      this.socketConnectedButNotInited = true;
      await this.initJwtToken();
    });

    this.socket.on('disconnect', (reason: any): void => {
      console.log('\x1b[31m' + 'Socket is disconnected!' + '\x1b[0m' + ' Reason: ' + reason);
      this.socketConnectedAndInitedEvent.next(false);

      if (reason === 'io server disconnect') {
        this.socketConnect();
      }
    });

    this.socket.on('connect_error', (error: any): void => {
      console.error('Socket connection error. ', error.message);
      setTimeout(() => {
        if (!this.onlineStateService.isOffline) {
          this.socketConnect();
        }
      }, 1000);
    });

    this.socket.on('answer', (data: { type: string, response: boolean, message: string }): void => {
      if (data.type === 'init' && data.response && this.socketConnectedButNotInited) {
        // console.log('\x1b[35m' + `Socket event 'answer'` + '\x1b[0m', data.message);
        this.socketConnectedButNotInited = false;
        this.socketConnectedAndInitedEvent.next(true);
      }
    });

    //chatEvents

    this.socket.on('message', (data: any): void => {
      // console.log('\x1b[35m' + `Socket event 'message'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(data, 'message');
      this.chatEvent.next({
        type: 'message',
        data
      });
    });

    this.socket.on('replyStatusChanged', (data: any): void => {
      // console.log('\x1b[35m' + `Socket event 'replyStatusChanged'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(data, 'replyStatusChanged');
      this.chatEvent.next({
        type: 'replyStatusChanged',
        data
      });
    });

    this.socket.on('taskCreated', (data: number): void => {
      // console.log('\x1b[35m' + `Socket event 'taskCreated'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(data, 'taskCreated');
      this.chatEvent.next({
        type: 'taskCreated',
        data
      });
    });

    this.socket.on('qtyUnreadMessages', (data: any): void => {
      // console.log('\x1b[35m' + `Socket event 'qtyUnreadMessages'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(data, 'qtyUnreadMessages');
      this.chatEvent.next({
        type: 'qtyUnreadMessages',
        data
      });
    });

    this.socket.on('chatCreated', (data: { chatId: number }): void => {
      // console.log('\x1b[35m' + `Socket event 'chatCreated'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'chatCreated');
      this.chatEvent.next({
        type: 'chatCreated',
        data
      });
    });

    this.socket.on('dataUpdated', (data: any): void => {
      // console.log('\x1b[35m' + `Socket event 'dataUpdated'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'dataUpdated');
      this.chatEvent.next({
        type: 'dataUpdated',
        data
      });
    });

    this.socket.on('getHistory', (docs: any): void => {
      // console.log('\x1b[35m' + `Socket event 'getHistory'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(docs, 'getHistory');
      this.chatEvent.next({
        type: 'getHistory',
        messages: docs
      });
    });

    this.socket.on('delivered', (guid: number): void => {
      // console.log('\x1b[35m' + `Socket event 'delivered'` + '\x1b[0m', data);
      this.validateReceivedData(guid, 'delivered');
      this.chatEvent.next({
        type: 'message_delivered',
        data: guid
      });
    });

    this.socket.on('undelivered', (guid: number): void => {
      // console.log('\x1b[35m' + `Socket event 'undelivered'` + '\x1b[0m', data);
      this.validateReceivedData(guid, 'undelivered');
      this.chatEvent.next({
        type: 'undelivered',
        data: guid
      });
    });

    this.socket.on('read', (data: { messages: any, chat_id: number, readBy: any }): void => {
      // console.log('\x1b[35m' + `Socket event 'read'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(data, 'read');
      this.chatEvent.next({
        type: 'read',
        data
      });
    });

    // this.socket.on('fileNotReady', (data: any): void => {
    //   // console.log('\x1b[35m' + `Socket event 'fileNotReady'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
    //   this.validateReceivedData(data, 'fileNotReady');
    //   this.chatEvent.next({
    //     type: 'fileNotReady',
    //     data
    //   });
    // });

    //notificationEvent

    this.socket.on('notification', (data: any): void => {
      // console.log('\x1b[33m' + `Socket event 'notification' from backend` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(data, 'notification');
      this.notificationEvent.next({
        type: 'notification',
        data
      });
    });

    //fileUploadEvent

    this.socket.on('fileUploaded', (data: { file: any }): void => {
      // console.log('\x1b[35m' + `Socket event 'fileUploaded'` + '\x1b[0m', JSON.parse(JSON.stringify(data)));
      this.validateReceivedData(data, 'fileUploaded');
      this.fileUploadEvent.next({
        type: 'fileUploaded',
        data
      });
    });

    //avatarEvents

    this.socket.on('avatarSyncStatus', (data: { is_online: boolean, turn_off: boolean }): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarSyncStatus'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarSyncStatus');
      this.avatarEvent.next({
        type: 'syncStatus',
        data
      });
    });

    this.socket.on('avatarStatusUpdated', (data: { user_id: number, status: number }): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarStatusUpdated'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarStatusUpdated');
      this.avatarEvent.next({
        type: 'status',
        data
      });
    });

    this.socket.on('avatarPhoneInfoRequest', (data: PhoneInfoRequestExt): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarPhoneInfoRequest'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarPhoneInfoRequest');
      this.avatarEvent.next({
        type: 'phoneInfoRequest',
        data
      });
    });

    this.socket.on('avatarPhoneInfoResponse', (data: PhoneInfoResponseExt): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarPhoneInfoResponse'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarPhoneInfoResponse');
      this.avatarEvent.next({
        type: 'phoneInfoResponse',
        data
      });
    });

    this.socket.on('avatarRequest', (order: any): void => {
      // console.log('\x1b[33m' + `Socket event 'avatarRequest' from backend` + '\x1b[0m', data);
      // this.validateReceivedData(data, 'avatarRequest');
      this.avatarEvent.next({
        type: 'request',
        data: order
      });
    });

    this.socket.on('avatarResponse', (data: { to_id: number, from_id: number, is_accepted: boolean }): void => {
      // console.log('\x1b[33m' + `Socket event 'avatarResponse' from backend` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarResponse');
      this.avatarEvent.next({
        type: 'response',
        data
      });
    });

    this.socket.on('avatarCustomerReady', (data: { to_id: number, order_id: number }): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarCustomerReady'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarCustomerReady');
      this.avatarEvent.next({
        type: 'customerReady',
        data
      });
    });

    this.socket.on('avatarOffer', (data: RTCSessionDescriptionInit): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarOffer'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarOffer');
      this.avatarEvent.next({
        type: 'offer',
        offer: data
      });
    });

    this.socket.on('avatarAnswer', (data: RTCSessionDescriptionInit): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarAnswer'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarAnswer');
      this.avatarEvent.next({
        type: 'answer',
        answer: data
      });
    });

    this.socket.on('avatarCandidate', (data: RTCIceCandidateInit): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarCandidate'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarCandidate');
      this.avatarEvent.next({
        type: 'candidate',
        candidate: data
      });
    });

    this.socket.on('avatarMessage', (command: string): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarMessage'` + '\x1b[0m', data);
      this.validateReceivedData(command, 'avatarMessage');
      this.avatarEvent.next({
        type: 'command',
        data: command
      });
    });

    this.socket.on('avatarExit', (data: boolean): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarExit'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarExit');
      this.avatarEvent.next({
        type: 'exit',
        data
      });
    });

    this.socket.on('avatarRequestReconnect', (data: { from_id: any, to_id: number, order_id: number }): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarRequestReconnect'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarRequestReconnect');
      this.avatarEvent.next({
        type: 'requestReconnect',
        request: data
      });
    });

    this.socket.on('avatarCustomerReadyReconnect', (data: {
      from_id: any,
      to_id: number,
      ready_reconnect: boolean,
      order_id: number
    }): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarCustomerReadyReconnect'` + '\x1b[0m', data);
      this.validateReceivedData(data, 'avatarCustomerReadyReconnect');
      this.avatarEvent.next({
        type: 'customerReadyReconnect',
        response: data
      });
    });

    this.socket.on('avatarZoom', (zoom: number): void => {
      // console.log('\x1b[35m' + `Socket event 'avatarAnswer'` + '\x1b[0m', data);
      this.validateReceivedData(zoom, 'avatarZoom');
      this.avatarEvent.next({
        type: 'zoom',
        data: zoom
      });
    });
  }

  //#region Validate data

  private validateReceivedData(eventData: Object, eventName: string): void {
    if (!eventData && eventData !== 0) console.error(`Received incorrect socket data from event '${eventName}'!`);
    this.validateObjectFields(eventData, `received from event '${eventName}'`);
  }

  private validateEmitData(eventData: Object, eventName: string): void {
    if (!eventName) console.error(`Incorrect event name!`);
    if (!eventData) console.error(`Incorrect sending data for the event '${eventName}'!`);
    this.validateObjectFields(eventData, `for emit event '${eventName}'`);
  }

  private validateObjectFields(obj: Object, eventDetails: string): void {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === undefined) {
          console.error(`Incorrect '${key}' ${eventDetails}`);
        }
      }
    }
  }

  //#endregion Validate data
}
