<div style="padding: 25px">
  <div class="modal-content">
    <ion-icon name='close' (click)="onCloseClick()"></ion-icon>
    <h2 class="title">{{ 'task.dispute.title' | translate }}</h2>
    <p class="article">
      {{'task.dispute.disclaimer' | translate}}
    </p>
    <div class="duration">
      <p class="duration-title">{{ 'task.dispute.durationTitle' | translate }}</p>
      <p class="duration-value">
        {{'task.dispute.duration' | translate}}
      </p>
    </div>
    <p class="article">
      {{'task.dispute.warning' | translate}}
    </p>
    <ion-button (click)="onOkClick()">{{ 'task.dispute.startResolving' | translate }}</ion-button>
  </div>
</div>
