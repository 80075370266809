<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button #bb></app-back-button>
    </ion-buttons>
    <ion-title>
      {{ 'reply.replyInfo.title' | translate }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onGoToChatClick()">
        <ion-icon name="chatbubble-ellipses"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-start ion-padding-end ion-padding-bottom">
  <ion-list *ngIf="reply">
    <ion-item class="user-info"
              *ngIf="reply.user"
              lines="none">
      <div class="avatar">
        <app-avatar class="ion-margin-end"
                    slot="start"
                    style="width: 44px; height: 44px"
                    sizeLight="none"
                    [name]="reply.user.name + ' ' + reply.user.surname"
                    [avatarFile]="reply.user?.avatar">
        </app-avatar>
      </div>
      <div class="info">
        <div class="owner">{{reply.user?.name}} {{reply.user?.surname}}</div>
        <div class="profession">
          {{ 'reply.replyInfo.blogger' | translate }}
          <span class="rating" *ngIf="reply?.user?.rating">{{reply.user.rating}}</span>
          <ion-icon *ngIf="reply?.user?.rating" class="star" src="assets/icon/icon-star.svg"></ion-icon>
        </div>
      </div>
    </ion-item>
    <ion-item class="dispute-result"
              *ngIf="reply.status == ReplyStatus.DISPUTE_SOLVED && reply.dispute_description"
              lines="none">
      <div>
        <p class="info">{{'reply.replyInfo.dispute' | translate:  getParams() }}</p>
      </div>
    </ion-item>
    <ion-item class="item" *ngIf="reply.status">
      <div>
        <h2 class="item-header">{{ 'reply.replyInfo.status' | translate }}</h2>
        <p class="info">{{reply.status | replyStatus}}</p>
      </div>
    </ion-item>
    <ion-item class="item">
      <div>
        <h2 class="item-header">{{ 'reply.replyInfo.comment' | translate }}</h2>
        <p class="info">{{reply.text}}</p>
      </div>
    </ion-item>
    <ion-item class="item" *ngIf="reply.price">
      <div>
        <h2 class="item-header">{{ 'reply.replyInfo.workerPrice' | translate }}</h2>
        <p class="info">{{reply.price}} {{ 'common.currency.rub' | translate }}</p>
      </div>
    </ion-item>
    <ion-item class="item" *ngIf="reply.deadline_at">
      <div>
        <h2 class="item-header">{{ 'reply.replyInfo.deadline' | translate }}</h2>
        <p class="info">{{reply.deadline_at | date : 'dd MMMM yyyy hh:mm'}}</p>
      </div>
    </ion-item>
    <ion-item class="item" *ngIf="reply.date_pending_at">
      <div>
        <h2 class="item-header">{{ 'reply.replyInfo.workerReplyTime' | translate }}</h2>
        <p class="info">{{reply.date_pending_at | date : 'dd MMMM yyyy hh:mm'}}</p>
      </div>
    </ion-item>
    <ion-item class="item" *ngIf="reply.date_accept_or_reject_at">
      <div>
        <h2 class="item-header">{{ 'reply.replyInfo.responseDate' | translate }}</h2>
        <p class="info">{{reply.date_accept_or_reject_at | date : 'dd MMMM yyyy hh:mm'}}</p>
      </div>
    </ion-item>
    <ion-item class="item container ion-no-padding"
              style="--inner-border-width: 0 0 0 0"
              *ngIf="this.reply.status == ReplyStatus.COMPLITED_BY_WORKER
                || this.reply.status == ReplyStatus.ACCEPTED_BY_OWNER
                || this.reply.status == ReplyStatus.DISPUTED">
      <div style="width: 100%; padding: 0">
        <div class="container task-result">
          <div class="header">{{ 'tasks.taskResults' | translate }}</div>
          <ion-button (click)="onShowTaskResultClick()">
            {{ 'tasks.showResults' | translate }}
          </ion-button>
        </div>
      </div>
    </ion-item>
    <ion-item class="item" *ngIf="reply.date_done_at">
      <div>
        <h2 class="item-header">{{ 'reply.replyInfo.orderAccepted' | translate }}</h2>
        <p class="info">{{reply.date_done_at | date : 'dd MMMM yyyy hh:mm'}}</p>
      </div>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer class="ion-no-border" *ngIf="this.reply && this.reply.user.id != this.authService.parsedToken.userId">
  <ion-button *ngIf="this.reply.status == ReplyStatus.PENDING"
              class="button-bottom"
              color="dark" fill="clear" expand="block"
              [appLoaderInButton]="onReplyRejectClick.bind(this)">
    {{ 'reply.replyInfo.reject' | translate }}
  </ion-button>
  <ion-button *ngIf="this.reply.status == ReplyStatus.PENDING"
              (click)="onReplyApproveClick()"
              class="ion-margin-bottom ion-padding-end ion-padding-start button-bottom"
              color="dark"
              expand="block">
    {{ 'reply.replyInfo.accept' | translate }}
  </ion-button>
</ion-footer>

<ion-footer class="ion-no-border" *ngIf="!!this.reply && this.reply?.user?.id == this.authService.parsedToken.userId">
  <ion-button *ngIf="this.reply.status == ReplyStatus.PENDING"
              class="button-bottom"
              color="dark" fill="clear" expand="block"
              [appLoaderInButton]="onReplyDeleteClick.bind(this)">
    {{ 'reply.replyInfo.return' | translate }}
  </ion-button>
  <ion-button *ngIf="this.reply.status == ReplyStatus.PENDING"
              (click)="onReplyEditClick()"
              class="ion-margin-bottom ion-padding-end ion-padding-start button-bottom"
              color="dark"
              expand="block">
    {{ 'reply.replyInfo.edit' | translate }}
  </ion-button>

  <ion-button *ngIf="this.reply.status == ReplyStatus.ACCEPTED"
              (click)="onReplySendResultClick()"
              class="ion-margin button-bottom"
              color="dark"
              expand="block">
    {{ 'reply.replyInfo.finishTask' | translate }}
  </ion-button>
  <ion-button *ngIf="this.reply.status == ReplyStatus.ACCEPTED_BY_OWNER"
              (click)="onShowBalanceClick()"
              class="ion-margin button-bottom"
              color="dark"
              expand="block">
    {{ 'reply.replyInfo.showBalance' | translate }}
  </ion-button>
</ion-footer>
