import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Client, ReportRequest} from "../../api-clients/pyjam/client";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingService} from "../../services/loading.service";
import {NavigationService} from "../../services/navigation.service";
import {ToastService} from "../../services/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {ReportTypes} from "../../report/report-type.enum";
import {ModalController} from "@ionic/angular";
import {ModalComponentBase} from "../../services/modal-window";
import {Location} from "@angular/common";

@Component({
  selector: 'report-content-popup',
  templateUrl: './report-content-popup.component.html',
  styleUrls: ['./report-content-popup.component.scss'],
})
export class ReportContentPopupComponent extends ModalComponentBase implements OnInit {
  public form: FormGroup;
  public reportReasons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  @Input() private reportType: ReportType;
  @Input() private reportedObjectId: number;

  constructor(
    private client: Client,
    private t: TranslateService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    public modalController: ModalController,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public location: Location,
  ) {
    super(modalController, router, activatedRoute, location);
  }

  ngOnInit() {
    this.form = new FormGroup({
      reportReason: new FormControl(null, [
        Validators.required
      ]),
      description: new FormControl('', [
        Validators.required
      ])
    });
  }

  public onSendUserReportClick(): void {
    console.log(this.form.controls.reportReason.value)
    this.loadingService.start()
      .then(() =>
        this.client.reportPost(
          {
            description: this.form.controls.description.value,
            type: this.reportType,
            reason: this.form.controls.reportReason.value,
            object_id: this.reportedObjectId.toString(),
          } as ReportRequest
        ).toPromise().then((reportData) => reportData))
      .then(() => {
        this.toastService.info(this.t.instant('report.ComplaintWillBeConsidered')).then();
      })
      .catch(async ex => {
        // await this.httpErrorHandlerService.handleHttpException(ex);
      })
      .finally(async () => {
        this.closeModal();
        await this.loadingService.stop();
      });
  }

  closeModal() {
    super.dismiss().then();
  }
}

export enum ReportType {
  TASK = 1,
  TASK_COMMENT,
  CHAT_MESSAGE,
  TASK_RESULT = 7,
  USER_PROFILE,
  RATE,
  FILE
}
