<ion-header>
  <ion-toolbar>
    <ion-title>{{'stripeConfirm.return.title' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="container">
    <ion-icon color="success" name="checkmark-outline"></ion-icon>
    <h1>{{'stripeConfirm.return.message' | translate}}</h1>
    <p>{{'stripeConfirm.return.description' | translate}}</p>
  </div>
</ion-content>

