<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button></app-back-button>
    </ion-buttons>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-horizontal">
  <div class="login-form">
    <h2 class="title">
      {{ (phoneMode ? 'sign.phoneNumber' : 'signEmail.title') | translate }}
    </h2>

    <p class="subtitle">
      {{ (phoneMode ? 'sign.phoneNumberTip' : 'signEmail.subtitle') | translate }}
    </p>

    <ng-container *ngIf="loginForm">
      <ng-container *ngIf="phoneMode; else modeEmail">
        <form *ngIf="phoneMode" [formGroup]="loginForm">
          <app-phone-input [formControlName]="'phoneInput'"></app-phone-input>

          <div *ngIf="loginForm.get('phoneInput').touched && loginForm.get('phoneInput').invalid" class="error">
            <div *ngIf="loginForm.get('phoneInput').errors.required">
              {{ 'sign.enterPhoneNumber' | translate }}
            </div>

            <div *ngIf="loginForm.get('phoneInput').errors.phoneLength">
              {{ 'sign.incorrectNumber' | translate }} {{ loginForm.get('phoneInput').errors.phoneLength }}
            </div>
          </div>
        </form>

        <agreements-checkbox [loginForm]="loginForm" [controlName]="'phoneInput'" (agreementChecked)="agreementChecked = $event"></agreements-checkbox>
      </ng-container>

      <ng-template #modeEmail>
        <form [formGroup]="loginForm">
          <ion-input class="custom-ion-input email" type="email" [formControlName]="'email'"
                     [placeholder]="'signEmail.enterEmailPlaceholder' | translate"></ion-input>

          <div *ngIf="loginForm.get('email').touched && loginForm.get('email').invalid" class="error">
            <div *ngIf="loginForm.get('email').errors.required">
              {{ 'signEmail.enterEmail' | translate }}
            </div>

            <div *ngIf="loginForm.get('email').errors.email || loginForm.get('email').errors.pattern">
              {{ 'signEmail.invalidEmail' | translate }}
            </div>
          </div>
        </form>

        <agreements-checkbox [loginForm]="loginForm" [controlName]="'email'" (agreementChecked)="agreementChecked = $event"></agreements-checkbox>
      </ng-template>
    </ng-container>

    <div class="other-auth-methods">
      <ion-button *ngIf="!platformService.isDevice || platformService.isDevice && platformService.isAndroid"
                  (click)="googleSignIn()"
                  class="center-btn"
                  color="dark"
                  expand="block"
                  fill="outline"
                  shape="round">
        <div>
          <img alt="logo google" class="login-icon google" src="assets/icon/google.png">
        </div>

        <div class="ion-margin-start">{{ 'registration.googleLogin' | translate }}</div>
      </ion-button>

      <ion-button *ngIf="!platformService.isDevice || platformService.isDevice && platformService.isIos"
                  (click)="appleSignIn()"
                  class="center-btn"
                  color="dark"
                  expand="block"
                  fill="outline"
                  shape="round">
        <div>
          <ion-icon class="login-icon" name="logo-apple"></ion-icon>
        </div>

        <div class="ion-margin-start">
          {{ 'registration.appleLogin' | translate }}
        </div>
      </ion-button>

      <ion-button (click)="toggleMode()"
                  class="center-btn"
                  color="dark"
                  expand="block"
                  fill="outline"
                  shape="round">
        <div>
          <ion-icon *ngIf="phoneMode" class="login-icon" name="mail-outline"></ion-icon>
          <ion-icon *ngIf="!phoneMode" class="login-icon" name="phone-portrait-outline" style="padding-left: 5px"></ion-icon>
        </div>

        <div class="ion-margin-start">
          {{ (phoneMode ? 'registration.emailLogin' : 'registration.phoneLogin') | translate }}
        </div>
      </ion-button>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-padding-bottom ion-padding-horizontal">
  <ion-button class="button-bottom"
              color="dark" expand="block"
              [appLoaderInButton]="phoneMode ? phoneSignIn.bind(this) : emailSignIn.bind(this)"
              [disabled]="loginForm?.invalid || !agreementChecked">
    {{ 'registration.lang.getCode' | translate }}
  </ion-button>
</ion-footer>
