import {
  PickedFile
} from "@capawesome/capacitor-file-picker";
import {
  ExtendedClient,
} from "../api-clients/pyjam/extended-client";
import {
  ToastService
} from "./toast.service";
import {
  LoadingService
} from "./loading.service";
import {
  Injectable
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {AddingFile} from "../ui-components/image-adding/image-adding.component";
import { HttpClient } from "@angular/common/http";
import { AddingFileWithDescription } from "../ui-components/add-description-to-image/add-description-to-image.component";
import { BriefAddingFile } from "../task/task.controller";

@Injectable({
  providedIn: 'root'
})
export class FileUploadingService {
  private EXCLUDED_EXTENSIONS = ["exe", "bat", "com", "py", "sh", "php", "jsp", "asp", "torrent", "dll"];
  private FILES_SIZE_LIMIT: number = 523239424;

  constructor(
    private extendedClient: ExtendedClient,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
  }

  public async pickedFilesToAddingFiles(pickedFiles: PickedFile[]): Promise<AddingFile[]>{
    let files = pickedFiles
      .filter(file => {
        if (file.name.lastIndexOf(".") > 0) {
          let fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length);
          return !this.EXCLUDED_EXTENSIONS.includes(fileExtension);
        }

        return true;
      })
      .map(pickedFile => {
        return {
          name: pickedFile.name,
          size: pickedFile.size,
          mimeType: pickedFile.mimeType,
          base64: `data:${pickedFile.mimeType};base64,${pickedFile.data}`,
          blob: pickedFile.blob,
          src: pickedFile.mimeType.includes('image') || pickedFile.mimeType.includes('video') ? `data:${pickedFile.mimeType};base64,${pickedFile.data}` : '',
        } as AddingFile
      });

    if (files.length !== pickedFiles.length) {
      await this.toastService.warning(this.translateService.instant('errors.forbiddenToUpload'));
      if (files.length < 1) {
        await this.loadingService.stop();
        return;
      }
    }

    let totalSize = files.reduce((totalSize, file) => totalSize + file.size, 0);
    if (totalSize >= this.FILES_SIZE_LIMIT) {
      await this.toastService.warning(this.translateService.instant('errors.maxSize'));
      await this.loadingService.stop();
      return;
    }

    return files;
  }

  public async profileAddFileToS3(file: AddingFileWithDescription) {
    console.log('profileAddFileToS3!', file);
    let formFields = await this.extendedClient.profileFileAddFileBySignUrl(file.name, file.mimeType, file.description).toPromise()
      .then((data) => {
        return data;
      }).catch((err) => {
        console.log(err);
        return err;
      });

    return await this.sendToC3(formFields, file, {'X-Amz-Meta-description': file.description});
  }

  public async taskAddFileToS3(taskId: number, file: BriefAddingFile) {
    let formFields = await this.extendedClient.taskAddFileBySignUrl(taskId, file.name, file.mimeType, file.question_id || undefined).toPromise()
      .then((data) => {
        return data;
      }).catch((err) => {
        console.log(err);
        return err;
      });

    return await this.sendToC3(formFields, file, {'X-Amz-Meta-task-id': taskId, 'X-Amz-Meta-question-id': file.question_id || ''});
  }

  public async taskResultAddFileToS3(taskResultId: number, file: AddingFile) {
    console.log('taskResultAddFileToS3!', file);
    let formFields = await this.extendedClient.taskResultAddFileBySignUrl(taskResultId, file.name, file.mimeType).toPromise()
      .then((data) => {
        return data;
      }).catch((err) => {
        console.log(err);
        return err;
      });

    return await this.sendToC3(formFields, file, {'X-Amz-Meta-task-result-id': taskResultId});
  }

  public async draftAddFileToS3(draftId: number, questionId: number | undefined, file: AddingFile) {
    if (!file.base64 && !file.blob) {
        console.log('File has only URL and will not be sent to C3!');
        return;
    }
    console.log('draftAddFileToS3!', file);
    let formFields = await this.extendedClient.draftAddFileBySignUrl(draftId, file.name, file.mimeType, questionId || undefined).toPromise()
      .then((data) => {
        return data;
      }).catch((err) => {
        console.log(err);
        return err;
      });

    return await this.sendToC3(formFields, file, {'X-Amz-Meta-draft-id': draftId, 'X-Amz-Meta-question-id': questionId || ''});
  }

  public async chatAddFileToS3(chatId: number, file: AddingFile) {
    console.log('chatAddFileToS3!', file);
    let formFields = await this.extendedClient.chatAddFileBySignUrl(chatId, file.name, file.mimeType).toPromise()
      .then((data) => {
        console.log('resonse:', data);
        return data;
      }).catch((err) => {
        console.log(err);
        return err;
      });

    return await this.sendToC3(formFields, file, {'X-Amz-Meta-chat-id': chatId});
  }

  public async userAddAvatarToS3(file: AddingFile) {
    console.log('userAddAvatarToS3!', file);
    let formFields = await this.extendedClient.userAddAvatarBySignUrl(file.name, file.mimeType).toPromise()
      .then((data) => {
        return data;
      }).catch((err) => {
        console.log(err);
        return err;
      });

    return await this.sendToC3(formFields, file);
  }

  private async sendToC3(formFields, file: AddingFile, additionalFields = {}) {
    let formData: any = new FormData();
    if (!file.blob) {
        if (!file.base64) {
            console.error('No base64 file data!');
            return;
        }
        file.blob = this.base64ToBlob(file.base64);
    }

    formData.append("key", formFields.key);
    formData.append("Content-Type", formFields.contentType);
    formData.append("X-Amz-Credential", formFields.xAmzCredential);
    formData.append("X-Amz-Algorithm", formFields.xAmzAlgorithm);
    formData.append("X-Amz-Date", formFields.xAmzDate);
    formData.append("Policy", formFields.policy);
    formData.append("X-Amz-Signature", formFields.xAmzSignature);
    formData.append("X-Amz-Meta-object-type", formFields.xAmzMetaObjectType);
    Object.keys(additionalFields).forEach((field) => {
      formData.append(field, additionalFields[field]);
    });
    formData.append("file", file.blob);

    return await this.http.post(formFields.action, formData).toPromise().then((data) => {
      console.log('File successfully uploaded');
    }, err => {
      console.log(err);
    });
  }

  private base64ToBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab]);
  }
}
