<div class="content-slot">
  <div class="ion-padding-bottom payout">

    <ion-select
      *ngIf="!pkCode && vm.gateway == 'stripe'"
      placeholder="Choose payment key"
      (ionChange)="selectPaymentMethod($event)">
      <ion-select-option value="pk_test_51LUWeIHbIgTBN9kjYigfRG5xj48exoC0vH7LIp1M485GiPDhINHyyLV2nDxJ0aJSDuDkuAaSEMQaJjBsP1oHGLmx00R8ojv26P">Test</ion-select-option>
      <ion-select-option value="pk_live_51LUWeIHbIgTBN9kjjXXpqmMcuorA3cMgjUmOFDpn5VJrOPX2zW6MQG77M4EcpHmw7pXOMltilZP9mYnSIzQo1ALn00LkiJyGaK">Live</ion-select-option>
    </ion-select>

    <div class="card-slot" *ngIf="pkCode && vm.gateway == 'stripe' || vm.gateway == 'paypal'">
      <div #frm id="payment-form" class="form">

        <div [ngSwitch]="vm.gateway">
          <div *ngSwitchCase="'stripe'">
            <div id="card-element" class="ion-padding"></div>
          </div>
          <div *ngSwitchCase="'paypal'">
            <!-- <div id="paypal-button-container" class="paypal-button-container"></div> -->
            <label class="paypal-name-label">{{ 'reply.payment.card.name' | translate }}</label>
            <div id="card-name-field-container" class="paypal paypal-name"></div>
            <label class="paypal-number-label">{{ 'reply.payment.card.number' | translate }}</label>
            <div id="card-number-field-container" class="paypal paypal-number"></div>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <label class="paypal-expiry-label">{{ 'reply.payment.card.expiry' | translate }}</label>
                </ion-col>
                <ion-col>
                  <label class="paypal-cvv-label">{{ 'reply.payment.card.cvv' | translate }}</label>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <div id="card-expiry-field-container" class="paypal paypal-expiry"></div>
                </ion-col>
                <ion-col>
                  <div id="card-cvv-field-container" class="paypal paypal-cvv"></div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>

        <div class="error">{{cardErrors}}</div>
        <div *ngIf="vm.amount" class="amount">
          <div class="info">{{ 'reply.payment.toPayment' | translate }}</div>
          <div class="value">{{+vm.amount | priceLocale}} {{vm.currency.toUpperCase()}}</div>
        </div>
      </div>
      <div class="footer ion-padding-start ion-padding-end">
        <ion-button (click)="sendCardToken()"
                    class="button-bottom"
                    type="default"
                    color="dark"
                    expand="block">
          {{ 'reply.payment.pay' | translate }}
        </ion-button>
      </div>
    </div>
  </div>
</div>
