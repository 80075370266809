<ion-header class="black">
  <ion-toolbar>
    <ion-title>{{ 'notifications.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="authService.isAuthenticated; else login_btn" class="notifications-slot ion-padding-bottom ion-padding-start ion-padding-end"
       infinite-scroll
       id="infiniteScroll"
       [horizontal]="false"
       [scrollWindow]="false"
       [infiniteScrollThrottle]="0"
       (scrolled)="onScrollDown()">
    <div class="notification-card ion-justify-content-between"
         *ngFor="let notification of notifications; let i = index" (click)="check(notification)">
      <div class="icon-slot type-1-green"
           *ngIf="notification.type === 1 || notification.type === 2 || notification.type === 3
                  || notification.type === 4 || notification.type === 5 || notification.type === 7 || notification.type === 8">
        <div class="icon">
          <ion-icon name="add-outline" color="light"></ion-icon>
        </div>
      </div>

      <div class="icon-slot type-2-purple"
           *ngIf="notification.type === 6 || notification.type === 9 || notification.type === 10
                  || notification.type === 19 || notification.type === 13 || notification.type === 20
                  || notification.type === 21 || notification.type === 22">
        <div class="icon">
          <ion-icon name="information-circle-outline" color="light"></ion-icon>
        </div>
      </div>

      <div class="icon-slot type-3-red"
           *ngIf="notification.type === 12 || notification.type === 11 || notification.type === 14
                  || notification.type === 16 || notification.type === 17 || notification.type === 18 || notification.type === 31">
        <div class="icon">
          <ion-icon name="alert-outline" color="light"></ion-icon>
        </div>
      </div>

      <div class="icon-slot" *ngIf="notification.type > 22">
        <div class="icon">
          <ion-icon name="analytics-outline" color="light"></ion-icon>
        </div>
      </div>
      <div class="info-slot">
        <div class="info" style="width: 100%">
          <div [ngSwitch]="notification.type">
            <div *ngSwitchCase="1">
              {{ 'notifications.newMessage' | translate }}
            </div>
            <div *ngSwitchCase="2">
              {{ 'notifications.taskNewComment' | translate }}
            </div>
            <div *ngSwitchCase="3">
              {{ 'notifications.newResponse' | translate }}
            </div>
            <div *ngSwitchCase="4">
              {{ 'notifications.newOrder' | translate: {value: notification.body.taskName} }}
            </div>
            <div *ngSwitchCase="5">
              <span *ngIf="notification.body.replyStatus == 15">
                {{ 'notifications.disputedResolve' | translate : {order: notification.body.taskName} }}
              </span>
              <span *ngIf="notification.body.replyStatus != 15">
                {{ 'notifications.newOrderStatus' | translate: {
                          order: notification.body.taskName,
                          status: 'notifications.orderStatus.' + notification.body.replyStatus | translate
                      } }}
              </span>
            </div>
            <div *ngSwitchCase="6">
              {{ 'notifications.fixRequest' | translate: {order: notification.body.taskName} }}
            </div>
            <div *ngSwitchCase="7">
              {{ 'notifications.newTask' | translate }}
            </div>
            <div *ngSwitchCase="8">
              {{ 'notifications.newChatWasCreated' | translate }}
            </div>
            <div *ngSwitchCase="9">
              {{ 'notifications.paymentSucceeded' | translate: {task: notification.body.taskName} }}
            </div>
            <div *ngSwitchCase="10">
              {{ 'notifications.onboardingUpdated' | translate }}
            </div>
            <div *ngSwitchCase="11">
              {{ 'notifications.waitingForDecisionOrUncompleted' | translate }}
            </div>
            <div *ngSwitchCase="12">
              {{ 'notifications.warningIfDoNothing' | translate }}
            </div>
            <div *ngSwitchCase="13">
              {{ 'notifications.workIsDoneWillTakeMoneyIn3Days' | translate }}
            </div>
            <div *ngSwitchCase="14">
              {{ 'notifications.disputeOpened' | translate: {
                        value: notification.body.isOwner ? ('notifications.customer' | translate) : ('notifications.executor' | translate),
                        task: notification.body.taskName
                    } }}
            </div>
            <div *ngSwitchCase="15">
              {{ 'notifications.executorIsReady' | translate: {task: notification.body.taskName} }}
            </div>
            <div *ngSwitchCase="16">
              {{ 'notifications.executorRejectedOrder' | translate: {task: notification.body.taskName} }}
            </div>
            <div *ngSwitchCase="17">
              {{ 'notifications.timeIsEndingForOrder' | translate: {task: notification.body.taskName} }}
            </div>
            <div *ngSwitchCase="18">
              {{ 'notifications.executorRejectedFixes' | translate }}
            </div>
            <div *ngSwitchCase="19">
              {{ 'notifications.executorOfferedPayment' | translate }}
            </div>
            <div *ngSwitchCase="20">
              {{ 'notifications.customerAgreed' | translate }}
            </div>
            <div *ngSwitchCase="21">
              {{ 'notifications.customerPayedFixed' | translate }}
            </div>
            <div *ngSwitchCase="22">
              {{ 'notifications.executorAgreed' | translate }}
            </div>
            <div *ngSwitchCase="23">
              {{ 'notifications.extraWorkPending' | translate }}
            </div>
            <div *ngSwitchCase="24">
              {{ 'notifications.extraWorkWorkerAddResults' | translate }}
            </div>
            <div *ngSwitchCase="25">
              {{ 'notifications.replyDataUpdated' | translate }}
            </div>
            <div *ngSwitchCase="26">
              {{ 'notifications.extraWorkWaitPay' | translate }}
            </div>
            <div *ngSwitchCase="27">
              {{ 'notifications.disputeCanceled' | translate }}
            </div>
            <div *ngSwitchCase="28">
              {{ 'notifications.extraWorkDeleted' | translate }}
            </div>
            <div *ngSwitchCase="29">
              {{ 'notifications.extraWorkOwnerApproveResults' | translate }}
            </div>
            <div *ngSwitchCase="30">
              {{ 'notifications.offerCreateTask' | translate: {task: notification.body.taskName, category: notification.body.categoryName} }}
            </div>
            <div *ngSwitchCase="31">
              {{ 'notifications.paymentFailed' | translate: {task: notification.body.taskName} }}
            </div>
            <div *ngSwitchCase="32">
              {{ 'notifications.paymentCanceled' | translate: {task: notification.body.taskName} }}
            </div>
            <div *ngSwitchCase="33">
              {{ 'notifications.paymentAfter5Days' | translate: {task: notification.body.taskName} }}
            </div>
            <div *ngSwitchCase="34">
              {{ 'notifications.disputedTaskForTrustedUser' | translate }}
            </div>
            <div *ngSwitchCase="35">
              {{ 'notifications.payForTrustedUser' | translate }}
            </div>
            <div *ngSwitchCase="36">
              {{ 'notifications.tips' | translate }}
            </div>
            <div *ngSwitchCase="37">
              {{ 'notifications.tips' | translate }}
            </div>
            <div *ngSwitchDefault>
              {{ 'notifications.notification' | translate }}
            </div>
          </div>
        </div>

        <div class="date">{{ notification.created_at | date: 'dd.MM.YYYY HH:mm' }}</div>
      </div>

      <div class="delete" (click)="delete($event, notification.id, i)">
        <ion-icon slot="icon-only" color="light" name="close"></ion-icon>
      </div>

    </div>
    <div *ngIf="notifications.length === 0" class="empty">
      {{ 'notifications.empty' | translate }}
    </div>
  </div>
  <ng-template #login_btn>
    <ion-button class="login-btn" shape="round" color="dark" expand="block" (click)="logInClick()">
      {{ 'registration.logIn' | translate }}
    </ion-button>
  </ng-template>
</ion-content>

