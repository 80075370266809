<ion-header>
  <ion-toolbar>
    <ion-title>{{'stripeConfirm.refresh.title' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="container">
    <ion-icon color="danger" name="sad-outline"></ion-icon>
    <h1>{{'stripeConfirm.refresh.message' | translate}}</h1>
    <p>{{'stripeConfirm.refresh.description' | translate}}</p>
  </div>
</ion-content>
