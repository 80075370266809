<ion-header class="black title-center">
  <ion-toolbar>
    <ion-title>
      {{getTitle()}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onSkipClick()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="!isPaymentNeeded" class="content ion-padding">
    <form *ngIf="form" [formGroup]="form" class="custom-form">
      <div class="header">{{ 'reply.rateResult.estimateResult' | translate }}</div>
      <div class="field stars">
        <stars-rating formControlName="stars" [width]=250></stars-rating>
      </div>
      <div *ngIf="!currentUserIdIsPerformer" class="field">
        <div class="header-tips"> {{ 'reply.rateResult.tipsForExecutor' | translate }}</div>
        <div class="tips">
          <div class="tip" [ngClass]="{'selected': form.controls.tip.value === 5}"
               (click)="form.controls.tip.value === 5
               ? form.controls.tip.setValue(0)
               : form.controls.tip.setValue(5)">€ 5
          </div>
          <div class="tip" [ngClass]="{'selected': form.controls.tip.value === 10}"
               (click)="form.controls.tip.value === 10
               ? form.controls.tip.setValue(0)
               : form.controls.tip.setValue(10)">€ 10
          </div>
          <div class="tip" [ngClass]="{'selected': form.controls.tip.value === 15}"
               (click)="form.controls.tip.value === 15
               ? form.controls.tip.setValue(0)
               : form.controls.tip.setValue(15)">€ 15
          </div>
          <div class="tip" [ngClass]="{'selected': form.controls.tip.value
                                                   && form.controls.tip.value !== 5
                                                   && form.controls.tip.value !== 10
                                                   && form.controls.tip.value !== 15}"
               (click)="form.controls.tip.value
                        && form.controls.tip.value !== 5
                        && form.controls.tip.value !== 10
                        && form.controls.tip.value !== 15
                        ? form.controls.tip.setValue(0)
                        :inputSum.openInputSumForControl(form.controls.tip)">
            {{ 'reply.rateResult.anotherTip' | translate }}{{form.controls.tip.value
          && form.controls.tip.value !== 5
          && form.controls.tip.value !== 10
          && form.controls.tip.value !== 15 ? '(€ '+form.controls.tip.value+')' : ''}}</div>
        </div>
      </div>
      <div class="field">
        <ion-textarea formControlName="description"
                      class="custom-ion-textarea"
                      name="textarea"
                      rows="6"
                      maxlength="1500"
                      [placeholder]="'reply.rateResult.commentPlaceholder' | translate">
        </ion-textarea>
      </div>
    </form>
  </div>
  <app-payment-input *ngIf="isPaymentNeeded" [paymentIntentType] = 4 [tipAmount]="form.controls.tip.value"></app-payment-input>
</ion-content>

<ion-footer *ngIf="!isPaymentNeeded" class="ion-no-border">
  <ion-button class="ion-margin-start ion-margin-bottom ion-padding-end button-bottom"
              color="dark" expand="block"
              [appLoaderInButton]="onRateClick.bind(this)"
              [disabled]="form?.invalid || (!form?.controls?.tip?.value && !form?.controls?.stars?.value)">
    {{ 'reply.rateResult.continue' | translate }}
  </ion-button>
</ion-footer>

<app-input-sum [min]="1" [max]="100" #inputSum></app-input-sum>
