<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button #bb></app-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="ion-padding-bottom ion-padding-end ion-padding-start">
    <h1 class="ion-no-margin">Privacy Policy</h1>
    <p class="standard"><small>Last updated: February 22, 2023</small></p>
    <p class="standard">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure
      of Your information when You use the Service and tells You about Your privacy rights and how the law protects
      You.</p>
    <p class="standard">We use Your Personal data to provide and improve the Service. By using the Service, You agree to
      the collection and use of information in accordance with this Privacy Policy.</p>
    <h2>Interpretation and Definitions</h2>
    <h3 class="subtitle">Interpretation</h3>
    <p class="standard">The words of which the initial letter is capitalized have meanings defined under the following
      conditions. The following definitions shall have the same meaning regardless of whether they appear in singular
      or in plural.</p>
    <h3 class="subtitle">Definitions</h3>
    <p class="standard">For the purposes of this Privacy Policy:</p>
    <p class="simple-line"><span class="T2">Account</span> means a unique account created for You to access our
      Service or parts of our Service.</p>
    <p class="simple-line"><span class="T2">Affiliate</span> means an entity that controls, is controlled by or is
      under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest
      or other securities entitled to vote for election of directors or other managing authority.</p>
    <p class="simple-line"><span class="T2">Application</span> refers to Pyjam, the software program provided by
      the Company.</p>
    <p class="simple-line"><span class="T2">Business</span>, for the purpose of CCPA/CPRA, refers to the Company
      as the legal entity that collects Consumers' personal information and determines the purposes and means of the
      processing of Consumers' personal information, or on behalf of which such information is collected and that
      alone, or jointly with others, determines the purposes and means of the processing of consumers' personal
      information, that does business in the State of California.</p>
    <p class="simple-line"><span class="T2">CCPA</span> and/or <span class="T2">CPRA</span> refers to California
      Consumer Privacy Act (the "CCPA") as amended by the California Privacy Rights Act of 2020 (the "CPRA").</p>
    <p class="simple-line"><span class="T2">Company</span> (referred to as either "the Company", "We", "Us" or
      "Our" in this Agreement) refers to Eurasia Trade OÜ, Mere pst 4 - 207, Tallinn, Eesti, 10111.</p>
    <p class="simple-line">For the purpose of the GDPR, the Company is the Data Controller.</p>
    <p class="simple-line"><span class="T2">Consumer</span>, for the purpose of the CCPA/CPRA, means a natural
      person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in
      the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA
      who is outside the USA for a temporary or transitory purpose.</p>
    <p class="simple-line"><span class="T2">Country</span> refers to: Florida, United States</p>
    <p class="simple-line"><span class="T2">Data Controller</span>, for the purposes of the GDPR (General Data
      Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines
      the purposes and means of the processing of Personal Data.</p>
    <p class="simple-line"><span class="T2">Device</span> means any device that can access the Service such as a
      computer, a cellphone or a digital tablet.</p>
    <p class="simple-line"><span class="T2">Do Not Track</span> (DNT) is a concept that has been promoted by US
      regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to
      develop and implement a mechanism for allowing internet users to control the tracking of their online activities
      across websites.</p>
    <p class="simple-line"><span class="T2">GDPR</span> refers to EU General Data Protection Regulation.</p>
    <p class="simple-line"><span class="T2">Personal Data</span> is any information that relates to an identified
      or identifiable individual.</p>
    <p class="simple-line">For the purposes of GDPR, Personal Data means any information relating to You such as a
      name, an identification number, location data, online identifier or to one or more factors specific to the
      physical, physiological, genetic, mental, economic, cultural or social identity.</p>
    <p class="simple-line">For the purposes of the CCPA/CPRA, Personal Data means any information that identifies,
      relates to, describes or is capable of being associated with, or could reasonably be linked, directly or
      indirectly, with You.</p>
    <p class="simple-line"><span class="T2">Service</span> refers to the Application.</p>
    <p class="simple-line"><span class="T2">Service Provider</span> means any natural or legal person who
      processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the
      Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related
      to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR,
      Service Providers are considered Data Processors.</p>
    <p class="simple-line"><span class="T2">Third-party Social Media Service</span> refers to any website or any
      social network website through which a User can log in or create an account to use the Service.</p>
    <p class="simple-line"><span class="T2">Usage Data</span> refers to data collected automatically, either
      generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a
      page visit).</p>
    <p class="simple-line"><span class="T2">You</span> means the individual accessing or using the Service, or the
      company, or other legal entity on behalf of which such individual is accessing or using the Service, as
      applicable.</p>
    <p class="simple-line">Under GDPR, You can be referred to as the Data Subject or as the User as you are the
      individual using the Service.</p>
    <h2>Collecting and Using Your Personal Data</h2>
    <h3 class="subtitle">Types of Data Collected</h3>
    <h4 class="Heading_20_4">Personal Data</h4>
    <p class="standard">While using Our Service, We may ask You to provide Us with certain personally identifiable
      information that can be used to contact or identify You. Personally identifiable information may include, but is
      not limited to:</p>
    <p class="simple-line">Email address</p>
    <p class="simple-line">First name and last name</p>
    <p class="simple-line">Phone number</p>
    <p class="simple-line">Address, State, Province, ZIP/Postal code, City</p>
    <p class="simple-line">Bank account information in order to pay for products and/or services within the
      Service</p>
    <p class="simple-line">Usage Data</p>
    <p class="standard">When You pay for a product and/or a service via bank transfer, We may ask You to provide
      information to facilitate this transaction and to verify Your identity. Such information may include, without
      limitation:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">Date of birth 
        </p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Passport or National ID car.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Bank card statemen.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Other information linking You to an
          addres.</p>
      </li>
    </ul>
    <h4 class="Heading_20_4">Usage Data</h4>
    <p class="standard">Usage Data is collected automatically when using the Service.</p>
    <p class="standard">Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP
      address), browser type, browser version, the pages of our Service that You visit, the time and date of Your
      visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
    <p class="standard">When You access the Service by or through a mobile device, We may collect certain information
      automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID,
      the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use,
      unique device identifiers and other diagnostic data.</p>
    <p class="standard">We may also collect information that Your browser sends whenever You visit our Service or when
      You access the Service by or through a mobile device.</p>
    <h4 class="Heading_20_4">Information from Third-Party Social Media Services</h4>
    <p class="standard">The Company allows You to create an account and log in to use the Service through the following
      Third-party Social Media Services:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">Googl.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Faceboo.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Twitte.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">LinkedI.</p>
      </li>
    </ul>
    <p class="standard">If You decide to register through or otherwise grant us access to a Third-Party Social Media
      Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's
      account, such as Your name, Your email address, Your activities or Your contact list associated with that
      account.</p>
    <p class="standard">You may also have the option of sharing additional information with the Company through Your
      Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during
      registration or otherwise, You are giving the Company permission to use, share, and store it in a manner
      consistent with this Privacy Policy.</p>
    <h4 class="Heading_20_4">Information
      Collected while Using the Application</h4>
    <p class="standard">While using Our Application, in order to provide features of Our Application, We may collect,
      with Your prior permission:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">Information regarding your
          locatio.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Information from your Device's phone
          book (contacts list.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Pictures and other information from
          your Device's camera and photo librar.</p>
      </li>
    </ul>
    <p class="standard">We use this information to provide features of Our Service, to improve and customize Our
      Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may
      be simply stored on Your device.</p>
    <p class="standard">
      You can enable or disable access to this information at any time, through Your Device settings.
    </p>
    <h4 class="Heading_20_4">Location Data</h4>
    <p class="standard">Our application collects location data to enable the "Online Mode" feature for freelancers who want to start working as online guides, allowing their location to be shown on the map even when the application is closed or not in use.</p>
    <p class="standard">Our application accesses your device's location data to provide certain functionalities and enhance the user experience. This information is used for purposes such as:</p>
    <ul>
      <li><p class="P4" style="margin-left:0cm;">Providing location-based services within the app.</p></li>
      <li><p class="P4" style="margin-left:0cm;">Improving the accuracy of features that rely on geographical data.</p></li>
      <li><p class="P4" style="margin-left:0cm;">Enhancing the user experience by personalizing content based on location.</p></li>
    </ul>
    <p class="standard">How We Use Your Location Data:</p>
    <ul>
      <li><p class="P4" style="margin-left:0cm;">Location data is used solely for the intended purpose of service provision and user experience enhancement.</p></li>
      <li><p class="P4" style="margin-left:0cm;">We do not share or sell location data to third parties without your explicit consent.</p></li>
    </ul>
    <p class="standard">User Control:</p>
    <ul>
      <li><p class="P4" style="margin-left:0cm;">You have the ability to enable or disable location services at any time through your device settings.</p></li>
      <li><p class="P4" style="margin-left:0cm;">Disabling location services may affect the functionality of certain features within the app.</p></li>
    </ul>
    <p class="standard">Data Security:</p>
    <ul>
      <li><p class="P4" style="margin-left:0cm;">We take necessary measures to ensure that your location data is securely stored and processed.</p></li>
      <li><p class="P4" style="margin-left:0cm;">Access to location data is restricted to authorized personnel and is protected by industry-standard security protocols.</p></li>
    </ul>
    <p class="standard">By using this application, you agree to the collection and use of your location information as outlined in this privacy policy. If you have any questions or concerns, please contact us at support@pyjam.com.</p>



    <h3 class="subtitle">Use of Your Personal Data</h3>
    <p class="standard">The Company may use Personal Data for the following purposes:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">To provide and
                    maintain our Service</span>, including to monitor the usage of our Service.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">To manage Your
                    Account:</span> to manage Your registration as a user of the Service. The Personal Data You provide
          can give You access to different functionalities of the Service that are available to You as a
          registered user.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">For the performance of
                    a contract:</span> the development, compliance and undertaking of the purchase contract for the
          products, items or services You have purchased or of any other contract with Us through the
          Service.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">To contact You:</span>
          To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication,
          such as a mobile application's push notifications regarding updates or informative communications
          related to the functionalities, products or contracted services, including the security updates, when
          necessary or reasonable for their implementation.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">To provide You</span>
          with news, special offers and general information about other goods, services and events which we offer
          that are similar to those that you have already purchased or enquired about unless You have opted not to
          receive such information.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">To manage Your
                    requests:</span> To attend and manage Your requests to Us.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">To deliver targeted
                    advertising to You</span>: We may use Your information to develop and display content and
          advertising (and work with third-party vendors who do so) tailored to Your interests and/or location and
          to measure its effectiveness.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">For business
                    transfers:</span> We may use Your information to evaluate or conduct a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets,
          whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which
          Personal Data held by Us about our Service users is among the assets transferred.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">For other
                    purposes</span>: We may use Your information for other purposes, such as data analysis, identifying
          usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our
          Service, products, services, marketing and your experience.</p>
      </li>
    </ul>
    <p class="standard">We do not give, sell or trade the phone numbers of our customers/users. We may share Your personal information in the following situations:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">With Service
                    Providers:</span> Your personal information may be utilized by Service Providers strictly for the purpose of performing services on our behalf, such as analyzing the use of our Service, supporting and maintaining Our Service, processing payments, and customer support. These providers are bound by strict confidentiality agreements and are prohibited from using your information for any other purpose.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">For business
                    transfers:</span> In the event of a merger, acquisition, or sale of assets, personal information may be part of the transferred assets. In such cases, we will ensure that the entity receiving the information will comply with this Privacy Policy or provide an equivalent level of protection.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">With
                    Affiliates:</span> We may share Your information with Our affiliates solely for purposes that are in line with this Privacy Policy. 'Affiliates' includes Our parent company, subsidiaries, joint venture partners, or other companies under common control with Us.
          with Us.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">With business
                    partners:</span> We may provide Your information to our business partners in relation to the delivery of joint content or services. These partners cannot use your information for any purpose other than what's been specified in our partnership agreement and are obligated to maintain the confidentiality of your information.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">With other
                    users:</span> when You share personal information or otherwise interact in the public areas with
          other users, such information may be viewed by all users and may be publicly distributed outside. If You
          interact with other users or register through a Third-Party Social Media Service, Your contacts on the
          Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity.
          Similarly, other users will be able to view descriptions of Your activity, communicate with You and view
          Your profile.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">With Your
                    consent</span>: We may disclose Your personal information for any other purpose with Your
          consent.</p>
      </li>
    </ul>
    <h3 class="subtitle">Retention of Your Personal Data</h3>
    <p class="standard">The Company will retain Your Personal Data only for as long as is necessary for the purposes set
      out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our
      legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
      disputes, and enforce our legal agreements and policies.</p>
    <p class="standard">The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
      retained for a shorter period of time, except when this data is used to strengthen the security or to improve
      the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
    <h3 class="subtitle">Transfer of Your Personal Data</h3>
    <p class="standard">Your information, including Personal Data, is processed at the Company's operating offices and
      in any other places where the parties involved in the processing are located. It means that this information may
      be transferred to — and maintained on — computers located outside of Your state, province, country or other
      governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
    <p class="standard">Your consent to this Privacy Policy followed by Your submission of such information represents
      Your agreement to that transfer.</p>
    <p class="standard">The Company will take all steps reasonably necessary to ensure that Your data is treated
      securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
      organization or a country unless there are adequate controls in place including the security of Your data and
      other personal information.</p>
    <h3 class="subtitle">Delete Your Personal Data</h3>
    <p class="standard">You have the right to delete or request that We assist in deleting the Personal Data that We
      have collected about You.</p>
    <p class="standard">Our Service may give You the ability to delete certain information about You from within the
      Service.</p>
    <p class="standard">You may update, amend, or delete Your information at any time by signing in to Your Account, if
      you have one, and visiting the account settings section that allows you to manage Your personal information. You
      may also contact Us to request access to, correct, or delete any personal information that You have provided to
      Us.</p>
    <p class="standard">Please note, however, that We may need to retain certain information when we have a legal
      obligation or lawful basis to do so.</p>
    <h3 class="subtitle">Disclosure of Your Personal Data</h3>
    <h4 class="Heading_20_4">Business Transactions</h4>
    <p class="standard">If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
      transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different
      Privacy Policy.</p>
    <h4 class="Heading_20_4">Law enforcement</h4>
    <p class="standard">Under certain circumstances, the Company may be required to disclose Your Personal Data if
      required to do so by law or in response to valid requests by public authorities (e.g. a court or a government
      agency).</p>
    <h4 class="Heading_20_4">Other legal requirements</h4>
    <p class="standard">The Company may disclose Your Personal Data in the good faith belief that such action is
      necessary to:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">Comply with a legal obligatio.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Protect and defend the rights or
          property of the Compan.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Prevent or investigate possible
          wrongdoing in connection with the Servic.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Protect the personal safety of Users of
          the Service or the publi.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Protect against legal liabilit.</p>
      </li>
    </ul>
    <h3 class="subtitle">Security of Your Personal Data</h3>
    <p class="standard">The security of Your Personal Data is important to Us, but remember that no method of
      transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use
      commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
    <h2>Detailed Information on the Processing of Your Personal Data</h2>
    <p class="standard">The Service Providers We use may have access to Your Personal Data. These third-party vendors
      collect, store, use, process and transfer information about Your activity on Our Service in accordance with
      their Privacy Policies.</p>
    <h3 class="subtitle">Analytics</h3>
    <p class="standard">We may use third-party Service providers to monitor and analyze the use of our Service.</p>
    <p class="simple-line"><span class="T2">Google Analytics</span></p>
    <p class="simple-line">Google Analytics is a web analytics service offered by Google that tracks and reports
      website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared
      with other Google services. Google may use the collected data to contextualize and personalize the ads of its
      own advertising network.</p>
    <p class="simple-line">You may opt-out of certain Google Analytics features through your mobile device
      settings, such as your device advertising settings or by following the instructions provided by Google in their
      Privacy Policy: <a target="_blank" href="https://policies.google.com/privacy" class="external-link"><span
        class="external-link-text">https://policies.google.com/privacy</span></a></p>
    <p class="simple-line">For more information on the privacy practices of Google, please visit the Google
      Privacy &amp; Terms web page: <a target="_blank" href="https://policies.google.com/privacy" class="external-link"><span
        class="external-link-text">https://policies.google.com/privacy</span></a></p>
    <p class="simple-line"><span class="T2">Firebase</span></p>
    <p class="simple-line">Firebase is an analytics service provided by Google Inc.</p>
    <p class="simple-line">You may opt-out of certain Firebase features through your mobile device settings, such
      as your device advertising settings or by following the instructions provided by Google in their Privacy Policy:
      <a target="_blank" href="https://policies.google.com/privacy" class="external-link"><span
        class="external-link-text">https://policies.google.com/privacy</span></a></p>
    <p class="simple-line">We also encourage you to review the Google's policy for safeguarding your data: <a
      href="https://support.google.com/analytics/answer/6004245" class="external-link"><span
      class="external-link-text">https://support.google.com/analytics/answer/6004245</span></a></p>
    <p class="simple-line">For more information on what type of information Firebase collects, please visit the
      How Google uses data when you use our partners' sites or apps webpage: <a
        href="https://policies.google.com/technologies/partner-sites" class="external-link"><span
        class="external-link-text">https://policies.google.com/technologies/partner-sites</span></a></p>
    <p class="simple-line"><span class="T2">Matomo</span></p>
    <p class="simple-line">Matomo is a web analytics service. You can visit their Privacy Policy page here: <a
      href="https://matomo.org/privacy-policy" class="external-link"><span
      class="external-link-text">https://matomo.org/privacy-policy</span></a></p>
    <p class="simple-line"><span class="T2">Clicky</span></p>
    <p class="simple-line">Clicky is a web analytics service. Read the Privacy Policy for Clicky here: <a
      href="https://clicky.com/terms" class="external-link"><span
      class="external-link-text">https://clicky.com/terms</span></a></p>
    <p class="simple-line"><span class="T2">Statcounter</span></p>
    <p class="simple-line">Statcounter is a web traffic analysis tool. You can read the Privacy Policy for
      Statcounter here: <a target="_blank" href="https://statcounter.com/about/legal/" class="external-link"><span
        class="external-link-text">https://statcounter.com/about/legal/</span></a></p>
    <p class="simple-line"><span class="T2">Flurry Analytics</span></p>
    <p class="simple-line">Flurry Analytics service is provided by Yahoo! Inc.</p>
    <p class="simple-line">You can opt-out from Flurry Analytics service to prevent Flurry Analytics from using
      and sharing your information by visiting the Flurry's Opt-out page: <a
        href="https://developer.yahoo.com/flurry/end-user-opt-out/" class="external-link"><span
        class="external-link-text">https://developer.yahoo.com/flurry/end-user-opt-out/</span></a></p>
    <p class="simple-line">For more information on the privacy practices and policies of Yahoo!, please visit
      their Privacy Policy page: <a target="_blank" href="https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm"
                                    class="external-link"><span
        class="external-link-text">https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm</span></a></p>
    <p class="simple-line"><span class="T2">Mixpanel</span></p>
    <p class="simple-line">Mixpanel is provided by Mixpanel Inc.</p>
    <p class="simple-line">You can prevent Mixpanel from using your information for analytics purposes by
      opting-out. To opt-out of Mixpanel service, please visit this page: <a target="_blank"
                                                                             href="https://mixpanel.com/optout/"
                                                                             class="external-link"><span
        class="external-link-text">https://mixpanel.com/optout/</span></a></p>
    <p class="simple-line">For more information on what type of information Mixpanel collects, please visit the
      Terms of Use page of Mixpanel: <a target="_blank" href="https://mixpanel.com/terms/" class="external-link"><span
        class="external-link-text">https://mixpanel.com/terms/</span></a></p>
    <p class="simple-line"><span class="T2">Unity Analytics</span></p>
    <p class="simple-line">Unity Analytics is provided by Unity Technologies.</p>
    <p class="simple-line">Unity Analytics collects, stores, uses, processes, and transfers personal information
      to provide, operate, and improve Unity Technologies services, including personalized advertising services. For
      more information on what type of information Unity Technologies collects, please visit their Privacy Policy
      page: <a target="_blank" href="https://unity3d.com/legal/privacy-policy" class="external-link"><span
        class="external-link-text">https://unity3d.com/legal/privacy-policy</span></a>.</p>
    <p class="simple-line">You can prevent Unity Analytics from using your information by opting-out. To opt-out
      of Unity Analytics, please visit this page: <a target="_blank" href="https://unity3d.com/legal/privacy-policy"
                                                     class="external-link"><span class="external-link-text">https://unity3d.com/legal/privacy-policy</span></a>
    </p>
    <p class="simple-line">You may opt-out of all personalized advertising by enabling privacy features on Your
      mobile device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization (Android). See Your mobile
      device Help system for more information.</p>
    <h3 class="subtitle">Advertising</h3>
    <p class="standard">We may use Service Providers to show advertisements to You to help support and maintain Our
      Service.</p>
    <p class="simple-line"><span class="T2">Google AdSense &amp; DoubleClick Cookie</span></p>
    <p class="simple-line">Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use
      of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our
      Service or other websites on the Internet.</p>
    <p class="simple-line">You may opt out of the use of the DoubleClick Cookie for interest-based advertising by
      visiting the Google Ads Settings web page: <a target="_blank" href="http://www.google.com/ads/preferences/"
                                                    class="external-link"><span class="external-link-text">http://www.google.com/ads/preferences/</span></a>
    </p>
    <p class="simple-line"><span class="T2">AdMob by Google</span></p>
    <p class="simple-line">AdMob by Google is provided by Google Inc.</p>
    <p class="simple-line">You can opt-out from the AdMob by Google service by following the instructions
      described by Google: <a target="_blank" href="https://support.google.com/ads/answer/2662922?hl=en"
                              class="external-link"><span
        class="external-link-text">https://support.google.com/ads/answer/2662922?hl=en</span></a></p>
    <p class="simple-line">For more information on how Google uses the collected information, please visit the
      "How Google uses data when you use our partners' sites or app" page: <a
        href="https://policies.google.com/technologies/partner-sites" class="external-link"><span
        class="external-link-text">https://policies.google.com/technologies/partner-sites</span></a> or visit the
      Privacy Policy of Google: <a target="_blank" href="https://policies.google.com/privacy"
                                   class="external-link"><span
        class="external-link-text">https://policies.google.com/privacy</span></a></p>
    <p class="simple-line"><span class="T2">Bing Ads</span></p>
    <p class="simple-line">Bing Ads is an advertising service provided by Microsoft Inc.</p>
    <p class="simple-line">You can opt-out from Bing Ads by following the instructions on Bing Ads Opt-out page:
      <a target="_blank" href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
         class="external-link"><span
        class="external-link-text">https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</span></a>
    </p>
    <p class="simple-line">For more information about Bing Ads, please visit their Privacy Policy: <a
      href="https://privacy.microsoft.com/en-us/PrivacyStatement" class="external-link"><span
      class="external-link-text">https://privacy.microsoft.com/en-us/PrivacyStatement</span></a></p>
    <p class="simple-line"><span class="T2">Flurry</span></p>
    <p class="simple-line">Flurry is provided by Yahoo! Inc.</p>
    <p class="simple-line">You can opt-out of the Flurry service and prevent it from using and sharing your
      information by visiting the Flurry Opt-out page: <a target="_blank"
                                                          href="https://developer.yahoo.com/flurry/end-user-opt-out/"
                                                          class="external-link"><span
        class="external-link-text">https://developer.yahoo.com/flurry/end-user-opt-out/</span></a></p>
    <p class="simple-line">For more information on the privacy practices policies of Yahoo!, please visit their
      Privacy Policy: <a target="_blank" href="https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm"
                         class="external-link"><span
        class="external-link-text">https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm</span></a></p>
    <p class="simple-line"><span class="T2">InMobi</span></p>
    <p class="simple-line">InMobi is provided by InMobi Pte Ltd.</p>
    <p class="simple-line">You can opt-out from InMobi service by following the instructions as described on the
      InMobi Opt-out page: <a target="_blank" href="http://www.inmobi.com/page/opt-out/" class="external-link"><span
        class="external-link-text">http://www.inmobi.com/page/opt-out/</span></a></p>
    <p class="simple-line">For more information on the privacy practices and policies of InMobi, please visit the
      InMobi Privacy Policy: <a target="_blank" href="http://www.inmobi.com/privacy-policy/" class="external-link"><span
        class="external-link-text">http://www.inmobi.com/privacy-policy/</span></a></p>
    <p class="simple-line"><span class="T2">MoPub</span></p>
    <p class="simple-line">MoPub is provided by MobPub Inc.</p>
    <p class="simple-line">You can opt-out from MobPub service by following the instructions on MoPub Opt-out
      page: <a target="_blank" href="http://www.mopub.com/optout/" class="external-link"><span
        class="external-link-text">http://www.mopub.com/optout/</span></a></p>
    <p class="simple-line">For more information on what information MobPub collects and how it is used, please
      read MoPub Privacy Policy: <a target="_blank" href="http://www.mopub.com/legal/privacy/"
                                    class="external-link"><span
        class="external-link-text">http://www.mopub.com/legal/privacy/</span></a></p>
    <p class="simple-line"><span class="T2">StartApp</span></p>
    <p class="simple-line">StartApp is provided by StartApp Inc.</p>
    <p class="simple-line">You can opt-out from interest-based ads served by StartApp by following the
      instructions on their Privacy Policy, section "8. Your Choices and Controls": <a
        href="https://www.startapp.com/policy/privacy-policy/" class="external-link"><span
        class="external-link-text">https://www.startapp.com/policy/privacy-policy/</span></a></p>
    <p class="simple-line">For more information on what information StartApp collects and how it is used, please
      read their Privacy Policy: <a target="_blank" href="https://www.startapp.com/policy/privacy-policy/"
                                    class="external-link"><span
        class="external-link-text">https://www.startapp.com/policy/privacy-policy/</span></a></p>
    <p class="simple-line"><span class="T2">AdColony</span></p>
    <p class="simple-line">AdColony is provided by Jirbo Inc.</p>
    <p class="simple-line">You can opt-out from AdColony service by visiting the AdColony Opt-out page: <a
      href="https://www.adcolony.com/privacy-policy/" class="external-link"><span
      class="external-link-text">https://www.adcolony.com/privacy-policy/</span></a></p>
    <p class="simple-line">You can also opt-out from AdColony through the Digital Advertising Alliance in the USA
      <a target="_blank" href="http://www.aboutads.info/choices/" class="external-link"><span
        class="external-link-text">http://www.aboutads.info/choices/</span></a> or opt-out using your mobile
      device settings.</p>
    <p class="simple-line">For more information AdColony, please visit the Privacy Policy of AdColony: <a
      href="http://www.adcolony.com/privacy-policy/" class="external-link"><span
      class="external-link-text">http://www.adcolony.com/privacy-policy/</span></a></p>
    <p class="simple-line"><span class="T2">AppLovin</span></p>
    <p class="simple-line">AppLovin is provided by the AppLovin Corporation.</p>
    <p class="simple-line">If you'd like to opt-out from AppLovin service, please visit the AppLovin Opt-out page:
      <a target="_blank" href="https://www.applovin.com/optout" class="external-link"><span
        class="external-link-text">https://www.applovin.com/optout</span></a></p>
    <p class="simple-line">If you'd like to learn more about AppLovin, please visit the Privacy Policy page of
      AppLovin: <a target="_blank" href="https://www.applovin.com/privacy" class="external-link"><span
        class="external-link-text">https://www.applovin.com/privacy</span></a></p>
    <p class="simple-line"><span class="T2">Vungle</span></p>
    <p class="simple-line">Vungle is provided by Vungle Inc.</p>
    <p class="simple-line">You can opt-out from Vungle service by following the instructions as described by
      Vungle on their Privacy Policy page: <a target="_blank" href="http://vungle.com/privacy/"
                                              class="external-link"><span
        class="external-link-text">http://vungle.com/privacy/</span></a></p>
    <p class="simple-line">For more information about Vungle, please visit Vungle Privacy Policy: <a
      href="http://vungle.com/privacy/" class="external-link"><span
      class="external-link-text">http://vungle.com/privacy/</span></a></p>
    <p class="simple-line"><span class="T2">AdButler</span></p>
    <p class="simple-line">AdButler is an advertising service provided by Sparklit Networks Inc.</p>
    <p class="simple-line">For more information about AdButler, please visit their Privacy Policy: <a
      href="https://www.sparklit.com/agreements.spark?agreement=privacy" class="external-link"><span
      class="external-link-text">https://www.sparklit.com/agreements.spark?agreement=privacy</span></a></p>
    <p class="simple-line"><span class="T2">Unity Ads</span></p>
    <p class="simple-line">Unity Ads is provided by Unity Technologies.</p>
    <p class="simple-line">Unity Ads collects, stores, uses, processes, and transfers personal information to
      provide, operate, and improve Unity Technologies services, including personalized advertising services. For more
      information on what type of information Unity Technologies collects, please visit their Privacy Policy page: <a
        href="https://unity3d.com/legal/privacy-policy" class="external-link"><span
        class="external-link-text">https://unity3d.com/legal/privacy-policy</span></a>.</p>
    <p class="simple-line">You can prevent Unity Ads from using your information by opting-out. To opt-out of
      Unity Analytics, please visit this page: <a target="_blank" href="https://unity3d.com/legal/privacy-policy"
                                                  class="external-link"><span class="external-link-text">https://unity3d.com/legal/privacy-policy</span></a>
    </p>
    <p class="simple-line">You may opt-out of all personalized advertising by enabling privacy features on Your
      mobile device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization (Android). See Your mobile
      device Help system for more information.</p>
    <h3 class="subtitle">Text Message Consent</h3>
    <p class="standard">By providing your phone number, you agree to receive text messages from Pyjam. Message and data rates may apply. Message frequency varies.</p>
    <h3 class="subtitle">Email Marketing</h3>
    <p class="standard">We may use Your Personal Data to contact You with newsletters, marketing or promotional
      materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of
      these communications from Us by following the unsubscribe link or instructions provided in any email We send or
      by contacting Us.</p>
    <p class="standard">We may use Email Marketing Service Providers to manage and send emails to You.</p>
    <p class="simple-line"><span class="T2">Mailchimp</span></p>
    <p class="simple-line">Mailchimp is an email marketing sending service provided by The Rocket Science Group
      LLC.</p>
    <p class="simple-line">For more information on the privacy practices of Mailchimp, please visit their Privacy
      policy: <a target="_blank" href="https://mailchimp.com/legal/privacy/" class="external-link"><span
        class="external-link-text">https://mailchimp.com/legal/privacy/</span></a></p>
    <p class="simple-line"><span class="T2">AWeber</span></p>
    <p class="simple-line">AWeber is an email marketing sending service provided by AWeber Communications.</p>
    <p class="simple-line">For more information on the privacy practices of AWeber, please visit their Privacy
      policy: <a target="_blank" href="https://www.aweber.com/privacy.htm" class="external-link"><span
        class="external-link-text">https://www.aweber.com/privacy.htm</span></a></p>
    <p class="simple-line"><span class="T2">GetResponse</span></p>
    <p class="simple-line">GetResponse is an email marketing sending service provided by GetResponse.</p>
    <p class="simple-line">For more information on the privacy practices of GetResponse, please visit their
      Privacy policy: <a target="_blank" href="https://www.getresponse.com/legal/privacy.html"
                         class="external-link"><span
        class="external-link-text">https://www.getresponse.com/legal/privacy.html</span></a></p>
    <h3 class="subtitle">Payments</h3>
    <p class="standard">We may provide paid products and/or services within the Service. In that case, we may use
      third-party services for payment processing (e.g. payment processors).</p>
    <p class="standard">We will not store or collect Your payment card details. That information is provided directly to
      Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy.
      These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards
      Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS
      requirements help ensure the secure handling of payment information.</p>
    <p class="simple-line"><span class="T2">Apple Store In-App Payments</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://www.apple.com/legal/privacy/en-ww/" class="external-link"><span
      class="external-link-text">https://www.apple.com/legal/privacy/en-ww/</span></a></p>
    <p class="simple-line"><span class="T2">Google Play In-App Payments</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://www.google.com/policies/privacy/" class="external-link"><span
      class="external-link-text">https://www.google.com/policies/privacy/</span></a></p>
    <p class="simple-line"><span class="T2">Stripe</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a target="_blank" href="https://stripe.com/us/privacy"
                                                                    class="external-link"><span
      class="external-link-text">https://stripe.com/us/privacy</span></a></p>
    <p class="simple-line"><span class="T2">Shopify</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a target="_blank"
                                                                    href="https://www.shopify.com/legal/privacy"
                                                                    class="external-link"><span
      class="external-link-text">https://www.shopify.com/legal/privacy</span></a></p>
    <p class="simple-line"><span class="T2">WePay</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a target="_blank"
                                                                    href="https://go.wepay.com/privacy-policy"
                                                                    class="external-link"><span
      class="external-link-text">https://go.wepay.com/privacy-policy</span></a></p>
    <p class="simple-line"><span class="T2">WorldPay</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://www.worldpay.com/en-gb/privacy-policy" class="external-link"><span
      class="external-link-text">https://www.worldpay.com/en-gb/privacy-policy</span></a></p>
    <p class="simple-line"><span class="T2">PayPal</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://www.paypal.com/webapps/mpp/ua/privacy-full" class="external-link"><span
      class="external-link-text">https://www.paypal.com/webapps/mpp/ua/privacy-full</span></a></p>
    <p class="simple-line"><span class="T2">Braintree</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://www.braintreepayments.com/legal/braintree-privacy-policy" class="external-link"><span
      class="external-link-text">https://www.braintreepayments.com/legal/braintree-privacy-policy</span></a></p>
    <p class="simple-line"><span class="T2">FastSpring</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a target="_blank"
                                                                    href="http://fastspring.com/privacy/"
                                                                    class="external-link"><span
      class="external-link-text">http://fastspring.com/privacy/</span></a></p>
    <p class="simple-line"><span class="T2">Authorize.net</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://www.authorize.net/company/privacy/" class="external-link"><span
      class="external-link-text">https://www.authorize.net/company/privacy/</span></a></p>
    <p class="simple-line"><span class="T2">2Checkout</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://www.2checkout.com/policies/privacy-policy" class="external-link"><span
      class="external-link-text">https://www.2checkout.com/policies/privacy-policy</span></a></p>
    <p class="simple-line"><span class="T2">Sage Pay</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a target="_blank"
                                                                    href="https://www.sagepay.co.uk/policies"
                                                                    class="external-link"><span
      class="external-link-text">https://www.sagepay.co.uk/policies</span></a></p>
    <p class="simple-line"><span class="T2">Square</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://squareup.com/legal/privacy-no-account" class="external-link"><span
      class="external-link-text">https://squareup.com/legal/privacy-no-account</span></a></p>
    <p class="simple-line"><span class="T2">Go Cardless</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://gocardless.com/en-eu/legal/privacy/" class="external-link"><span
      class="external-link-text">https://gocardless.com/en-eu/legal/privacy/</span></a></p>
    <p class="simple-line"><span class="T2">Elavon</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://www.elavon.com/privacy-pledge.html" class="external-link"><span
      class="external-link-text">https://www.elavon.com/privacy-pledge.html</span></a></p>
    <p class="simple-line"><span class="T2">Verifone</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a target="_blank"
                                                                    href="https://www.verifone.com/en/us/legal"
                                                                    class="external-link"><span
      class="external-link-text">https://www.verifone.com/en/us/legal</span></a></p>
    <p class="simple-line"><span class="T2">Moneris</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://www.moneris.com/en/Privacy-Policy" class="external-link"><span
      class="external-link-text">https://www.moneris.com/en/Privacy-Policy</span></a></p>
    <p class="simple-line"><span class="T2">WeChat</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://www.wechat.com/en/privacy_policy.html" class="external-link"><span
      class="external-link-text">https://www.wechat.com/en/privacy_policy.html</span></a></p>
    <p class="simple-line"><span class="T2">Alipay</span></p>
    <p class="simple-line">Their Privacy Policy can be viewed at <a
      href="https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html"
      class="external-link"><span
      class="external-link-text">https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html</span></a>
    </p>
    <p class="standard">When You use Our Service to pay a product and/or service via bank transfer, We may ask You to
      provide information to facilitate this transaction and to verify Your identity.</p>
    <h3 class="subtitle">Behavioral Remarketing</h3>
    <p class="standard">The Company uses remarketing services to advertise to You after You accessed or visited our
      Service. We and Our third-party vendors use cookies and non-cookie technologies to help Us recognize Your Device
      and understand how You use our Service so that We can improve our Service to reflect Your interests and serve
      You advertisements that are likely to be of more interest to You.</p>
    <p class="standard">These third-party vendors collect, store, use, process and transfer information about Your
      activity on Our Service in accordance with their Privacy Policies and to enable Us to:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">Measure and analyze traffic and
          browsing activity on Our Servic.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Show advertisements for our products
          and/or services to You on third-party websites or app.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Measure and analyze the performance of
          Our advertising campaign.</p>
      </li>
    </ul>
    <p class="standard">Some of these third-party vendors may use non-cookie technologies that may not be impacted by
      browser settings that block cookies. Your browser may not permit You to block such technologies. You can use the
      following third-party tools to decline the collection and use of information for the purpose of serving You
      interest-based advertising:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">The NAI's opt-out platform: <a
          href="http://www.networkadvertising.org/choices/" class="external-link"><span
          class="external-link-text">http://www.networkadvertising.org/choices/</span></a>.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">The EDAA's opt-out platform <a
          href="http://www.youronlinechoices.com/" class="external-link"><span
          class="external-link-text">http://www.youronlinechoices.com/</span></a> 
        </p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">The DAA's opt-out platform: <a
          href="http://optout.aboutads.info/?c=2&amp;lang=EN" class="external-link"><span
          class="external-link-text">http://optout.aboutads.info/?c=2&amp;lang=EN</span></a>.</p>
      </li>
    </ul>
    <p class="standard">You may opt-out of all personalized advertising by enabling privacy features on Your mobile
      device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization (Android). See Your mobile device Help
      system for more information.</p>
    <p class="standard">We may share information, such as hashed email addresses (if available) or other online
      identifiers collected on Our Service with these third-party vendors. This allows Our third-party vendors to
      recognize and deliver You ads across devices and browsers. To read more about the technologies used by these
      third-party vendors and their cross-device capabilities please refer to the Privacy Policy of each vendor listed
      below.</p>
    <p class="standard">The third-party vendors We use are:</p>
    <p class="simple-line"><span class="T2">Google Ads (AdWords)</span></p>
    <p class="simple-line">Google Ads (AdWords) remarketing service is provided by Google Inc.</p>
    <p class="simple-line">You can opt-out of Google Analytics for Display Advertising and customise the Google
      Display Network ads by visiting the Google Ads Settings page: <a target="_blank"
                                                                       href="http://www.google.com/settings/ads"
                                                                       class="external-link"><span
        class="external-link-text">http://www.google.com/settings/ads</span></a></p>
    <p class="simple-line">Google also recommends installing the Google Analytics Opt-out Browser Add-on - <a
      href="https://tools.google.com/dlpage/gaoptout" class="external-link"><span
      class="external-link-text">https://tools.google.com/dlpage/gaoptout</span></a> - for your web browser.
      Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being
      collected and used by Google Analytics.</p>
    <p class="simple-line">For more information on the privacy practices of Google, please visit the Google
      Privacy &amp; Terms web page: <a target="_blank" href="https://policies.google.com/privacy" class="external-link"><span
        class="external-link-text">https://policies.google.com/privacy</span></a></p>
    <p class="simple-line"><span class="T2">Bing Ads Remarketing</span></p>
    <p class="simple-line">Bing Ads remarketing service is provided by Microsoft Inc.</p>
    <p class="simple-line">You can opt-out of Bing Ads interest-based ads by following their instructions: <a
      href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
      class="external-link"><span
      class="external-link-text">https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</span></a>
    </p>
    <p class="simple-line">You can learn more about the privacy practices and policies of Microsoft by visiting
      their Privacy Policy page: <a target="_blank" href="https://privacy.microsoft.com/en-us/PrivacyStatement"
                                    class="external-link"><span
        class="external-link-text">https://privacy.microsoft.com/en-us/PrivacyStatement</span></a></p>
    <p class="simple-line"><span class="T2">Twitter</span></p>
    <p class="simple-line">Twitter remarketing service is provided by Twitter Inc.</p>
    <p class="simple-line">You can opt-out from Twitter's interest-based ads by following their instructions: <a
      href="https://support.twitter.com/articles/20170405" class="external-link"><span
      class="external-link-text">https://support.twitter.com/articles/20170405</span></a></p>
    <p class="simple-line">You can learn more about the privacy practices and policies of Twitter by visiting
      their Privacy Policy page: <a target="_blank" href="https://twitter.com/privacy" class="external-link"><span
        class="external-link-text">https://twitter.com/privacy</span></a></p>
    <p class="simple-line"><span class="T2">Facebook</span></p>
    <p class="simple-line">Facebook remarketing service is provided by Facebook Inc.</p>
    <p class="simple-line">You can learn more about interest-based advertising from Facebook by visiting this
      page: <a target="_blank" href="https://www.facebook.com/help/516147308587266" class="external-link"><span
        class="external-link-text">https://www.facebook.com/help/516147308587266</span></a></p>
    <p class="simple-line">To opt-out from Facebook's interest-based ads, follow these instructions from Facebook:
      <a target="_blank" href="https://www.facebook.com/help/568137493302217" class="external-link"><span
        class="external-link-text">https://www.facebook.com/help/568137493302217</span></a></p>
    <p class="simple-line">Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising
      established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating
      companies through the Digital Advertising Alliance in the USA <a target="_blank"
                                                                       href="http://www.aboutads.info/choices/"
                                                                       class="external-link"><span
        class="external-link-text">http://www.aboutads.info/choices/</span></a>, the
      Digital Advertising Alliance of Canada in Canada <a target="_blank" href="http://youradchoices.ca/"
                                                          class="external-link"><span
        class="external-link-text">http://youradchoices.ca/</span></a> or the European Interactive Digital
      Advertising Alliance in Europe <a target="_blank" href="http://www.youronlinechoices.eu/"
                                        class="external-link"><span
        class="external-link-text">http://www.youronlinechoices.eu/</span></a>, or opt-out using your mobile
      device settings.</p>
    <p class="simple-line">For more information on the privacy practices of Facebook, please visit Facebook's Data
      Policy: <a target="_blank" href="https://www.facebook.com/privacy/explanation" class="external-link"><span
        class="external-link-text">https://www.facebook.com/privacy/explanation</span></a></p>
    <p class="simple-line"><span class="T2">Pinterest</span></p>
    <p class="simple-line">Pinterest remarketing service is provided by Pinterest Inc.</p>
    <p class="simple-line">You can opt-out from Pinterest's interest-based ads by enabling the "Do Not Track"
      functionality of your web browser or by following Pinterest instructions: <a
        href="http://help.pinterest.com/en/articles/personalization-and-data" class="external-link"><span
        class="external-link-text">http://help.pinterest.com/en/articles/personalization-and-data</span></a></p>
    <p class="simple-line">You can learn more about the privacy practices and policies of Pinterest by visiting
      their Privacy Policy page: <a target="_blank" href="https://about.pinterest.com/en/privacy-policy"
                                    class="external-link"><span
        class="external-link-text">https://about.pinterest.com/en/privacy-policy</span></a></p>
    <p class="simple-line"><span class="T2">AdRoll</span></p>
    <p class="simple-line">AdRoll remarketing service is provided by NextRoll, Inc.</p>
    <p class="simple-line">You can opt-out of AdRoll remarketing by visiting this AdRoll Advertising Preferences
      web page: <a target="_blank" href="http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false"
                   class="external-link"><span
        class="external-link-text">http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false</span></a> or the
      Opt Out of Personalized Advertising web page: <a
        href="https://help.adroll.com/hc/en-us/articles/216599538-Opting-Out-of-Personalized-Advertising"
        class="external-link"><span
        class="external-link-text">https://help.adroll.com/hc/en-us/articles/216599538-Opting-Out-of-Personalized-Advertising</span></a>
    </p>
    <p class="simple-line">If You are a California resident, please read the "Information For California Residents
      Only" section in the Privacy Policy of NextRoll, Inc.: <a target="_blank"
                                                                href="https://www.nextroll.com/privacy#service-13"
                                                                class="external-link"><span class="external-link-text">https://www.nextroll.com/privacy#service-13</span></a>
    </p>
    <p class="simple-line">For more information on the privacy practices of AdRoll, please visit the NextRoll,
      Inc. Privacy Policy web page: <a target="_blank" href="https://www.nextroll.com/privacy"
                                       class="external-link"><span
        class="external-link-text">https://www.nextroll.com/privacy</span></a></p>
    <p class="simple-line"><span class="T2">Perfect Audience</span></p>
    <p class="simple-line">Perfect Audience remarketing service is provided by NowSpots Inc.</p>
    <p class="simple-line">You can opt-out of Perfect Audience remarketing by visiting these pages: Platform
      Opt-out (<a target="_blank" href="http://pixel.prfct.co/coo" class="external-link"><span
        class="external-link-text">http://pixel.prfct.co/coo</span></a>) and Partner Opt-out (<a
        href="http://ib.adnxs.com/optout" class="external-link"><span
        class="external-link-text">http://ib.adnxs.com/optout</span></a>).</p>
    <p class="simple-line">For more information on the privacy practices of Perfect Audience, please visit the
      Perfect Audience Privacy Policy &amp; Opt-out web page: <a
        href="https://www.perfectaudience.com/privacy/index.html" class="external-link"><span
        class="external-link-text">https://www.perfectaudience.com/privacy/</span></a></p>
    <p class="simple-line"><span class="T2">AppNexus</span></p>
    <p class="simple-line">AppNexus remarketing service is provided by AppNexus Inc.</p>
    <p class="simple-line">You can opt-out of AppNexus remarketing by visiting the Privacy &amp; the AppNexus
      Platform web page: <a target="_blank" href="https://www.appnexus.com/platform-privacy-policy"
                            class="external-link"><span
        class="external-link-text">https://www.appnexus.com/platform-privacy-policy</span></a></p>
    <p class="simple-line">For more information on the privacy practices of AppNexus, please visit the AppNexus
      Platform Privacy Policy web page: <a target="_blank" href="https://www.appnexus.com/platform-privacy-policy"
                                           class="external-link"><span
        class="external-link-text">https://www.appnexus.com/platform-privacy-policy</span></a></p>
    <h2>GDPR Privacy</h2>
    <h3 class="subtitle">Legal Basis for Processing Personal Data under GDPR</h3>
    <p class="standard">We may process Personal Data under the following conditions:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Consent:</span> You
          have given Your consent for processing Personal Data for one or more specific purposes.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Performance of a
                    contract:</span> Provision of Personal Data is necessary for the performance of an agreement with
          You and/or for any pre-contractual obligations thereof.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Legal
                    obligations:</span> Processing Personal Data is necessary for compliance with a legal obligation to
          which the Company is subject.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Vital
                    interests:</span> Processing Personal Data is necessary in order to protect Your vital interests or
          of another natural person.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Public
                    interests:</span> Processing Personal Data is related to a task that is carried out in the public
          interest or in the exercise of official authority vested in the Company.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Legitimate
                    interests:</span> Processing Personal Data is necessary for the purposes of the legitimate interests
          pursued by the Company.</p>
      </li>
    </ul>
    <p class="standard">In any case, the Company will gladly help to clarify the specific legal basis that applies to
      the processing, and in particular whether the provision of Personal Data is a statutory or contractual
      requirement, or a requirement necessary to enter into a contract.</p>
    <h3 class="subtitle">Your Rights under the GDPR</h3>
    <p class="standard">The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You
      can exercise Your rights.</p>
    <p class="standard">You have the right under this Privacy Policy, and by law if You are within the EU, to:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Request access to Your
                    Personal Data.</span> The right to access, update or delete the information We have on You. Whenever
          made possible, you can access, update or request deletion of Your Personal Data directly within Your
          account settings section. If you are unable to perform these actions yourself, please contact Us to
          assist You. This also enables You to receive a copy of the Personal Data We hold about You.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Request correction of
                    the Personal Data that We hold about You.</span> You have the right to have any incomplete or
          inaccurate information We hold about You corrected.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Object to processing
                    of Your Personal Data.</span> This right exists where We are relying on a legitimate interest as the
          legal basis for Our processing and there is something about Your particular situation, which makes You
          want to object to our processing of Your Personal Data on this ground. You also have the right to object
          where We are processing Your Personal Data for direct marketing purposes.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Request erasure of
                    Your Personal Data.</span> You have the right to ask Us to delete or remove Personal Data when there
          is no good reason for Us to continue processing it.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Request the transfer
                    of Your Personal Data.</span> We will provide to You, or to a third-party You have chosen, Your
          Personal Data in a structured, commonly used, machine-readable format. Please note that this right only
          applies to automated information which You initially provided consent for Us to use or where We used the
          information to perform a contract with You.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Withdraw Your
                    consent.</span> You have the right to withdraw Your consent on using your Personal Data. If You
          withdraw Your consent, We may not be able to provide You with access to certain specific functionalities
          of the Service.</p>
      </li>
    </ul>
    <h3 class="subtitle">Exercising of Your GDPR Data Protection Rights</h3>
    <p class="standard">You may exercise Your rights of access, rectification, cancellation and opposition by contacting
      Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a
      request, We will try our best to respond to You as soon as possible.</p>
    <p class="standard">You have the right to complain to a Data Protection Authority about Our collection and use of
      Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your
      local data protection authority in the EEA.</p>
    <h2>CCPA/CPRA Privacy Notice</h2>
    <p class="standard">This privacy notice section for California residents supplements the information contained in
      Our Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of
      California.</p>
    <h3 class="subtitle">Categories of Personal Information Collected</h3>
    <p class="standard">We collect information that identifies, relates to, describes, references, is capable of being
      associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer or Device.
      The following is a list of categories of personal information which we may collect or may have been collected
      from California residents within the last twelve (12) months.</p>
    <p class="standard">Please note that the categories and examples provided in the list below are those defined in the
      CCPA/CPRA. This does not mean that all examples of that category of personal information were in fact collected
      by Us, but reflects our good faith belief to the best of Our knowledge that some of that information from the
      applicable category may be and may have been collected. For example, certain categories of personal information
      would only be collected if You provided such personal information directly to Us.</p>
    <p class="simple-line"><span class="T2">Category A: Identifiers.</span></p>
    <p class="simple-line">Examples: A real name, alias, postal address, unique personal identifier, online
      identifier, Internet Protocol address, email address, account name, driver's license number, passport number, or
      other similar identifiers.</p>
    <p class="simple-line">Collected: Yes.</p>
    <p class="simple-line"><span class="T2">Category B: Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code § 1798.80(e)).</span></p>
    <p class="simple-line">Examples: A name, signature, Social Security number, physical characteristics or
      description, address, telephone number, passport number, driver's license or state identification card number,
      insurance policy number, education, employment, employment history, bank account number, credit card number,
      debit card number, or any other financial information, medical information, or health insurance information.
      Some personal information included in this category may overlap with other categories.</p>
    <p class="simple-line">Collected: Yes.</p>
    <p class="simple-line"><span class="T2">Category C: Protected classification characteristics under California
            or federal law.</span></p>
    <p class="simple-line">Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship,
      religion or creed, marital status, medical condition, physical or mental disability, sex (including gender,
      gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation,
      veteran or military status, genetic information (including familial genetic information).</p>
    <p class="simple-line">Collected: No.</p>
    <p class="simple-line"><span class="T2">Category D: Commercial information.</span></p>
    <p class="simple-line">Examples: Records and history of products or services purchased or considered.</p>
    <p class="simple-line">Collected: Yes.</p>
    <p class="simple-line"><span class="T2">Category E: Biometric information.</span></p>
    <p class="simple-line">Examples: Genetic, physiological, behavioral, and biological characteristics, or
      activity patterns used to extract a template or other identifier or identifying information, such as,
      fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns,
      and sleep, health, or exercise data.</p>
    <p class="simple-line">Collected: No.</p>
    <p class="simple-line"><span class="T2">Category F: Internet or other similar network activity.</span></p>
    <p class="simple-line">Examples: Interaction with our Service or advertisement.</p>
    <p class="simple-line">Collected: Yes.</p>
    <p class="simple-line"><span class="T2">Category G: Geolocation data.</span></p>
    <p class="simple-line">Examples: Approximate physical location.</p>
    <p class="simple-line">Collected: Yes.</p>
    <p class="simple-line"><span class="T2">Category H: Sensory data.</span></p>
    <p class="simple-line">Examples: Audio, electronic, visual, thermal, olfactory, or similar information.</p>
    <p class="simple-line">Collected: No.</p>
    <p class="simple-line"><span class="T2">Category I: Professional or employment-related information.</span></p>
    <p class="simple-line">Examples: Current or past job history or performance evaluations.</p>
    <p class="simple-line">Collected: No.</p>
    <p class="simple-line"><span class="T2">Category J: Non-public education information (per the Family
            Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</span></p>
    <p class="simple-line">Examples: Education records directly related to a student maintained by an educational
      institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student
      identification codes, student financial information, or student disciplinary records.</p>
    <p class="simple-line">Collected: No.</p>
    <p class="simple-line"><span class="T2">Category K: Inferences drawn from other personal information.</span>
    </p>
    <p class="simple-line">Examples: Profile reflecting a person's preferences, characteristics, psychological
      trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p>
    <p class="simple-line">Collected: No.</p>
    <p class="simple-line"><span class="T2">Category L: Sensitive personal information.</span></p>
    <p class="simple-line">Examples: Account login and password information, geolocation data.</p>
    <p class="simple-line">Collected: Yes.</p>
    <p class="standard">Under CCPA/CPRA, personal information does not include:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">Publicly available information from
          government record.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Deidentified or aggregated consumer
          informatio.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Information excluded from the
          CCPA/CPRA's scope, such as:.</p>
      </li>
    </ul>
    <ul>
      <li>
        <p class="P2" style="margin-left:0cm;">Health or medical information covered
          by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California
          Confidentiality of Medical Information Act (CMIA) or clinical trial dat.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Personal Information covered by certain
          sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act
          (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act
          of 199.</p>
      </li>
    </ul>
    <h3 class="subtitle">Sources of Personal Information</h3>
    <p class="standard">We obtain the categories of personal information listed above from the following categories of
      sources:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Directly from
                    You</span>. For example, from the forms You complete on our Service, preferences You express or
          provide through our Service, or from Your purchases on our Service.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Indirectly from
                    You</span>. For example, from observing Your activity on our Service.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">Automatically from
                    You</span>. For example, through cookies We or our Service Providers set on Your Device as You
          navigate through our Service.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">From Service
                    Providers</span>. For example, third-party vendors to monitor and analyze the use of our Service,
          third-party vendors to provide advertising on our Service, third-party vendors to deliver targeted
          advertising to You, third-party vendors for payment processing, or other third-party vendors that We use
          to provide the Service to You.</p>
      </li>
    </ul>
    <h3 class="subtitle">Use of Personal Information</h3>
    <p class="standard">We may use or disclose personal information We collect for "business purposes" or "commercial
      purposes" (as defined under the CCPA/CPRA), which may include the following examples:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">To
          operate our Service and provide You with Our Service.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">To
          provide You with support and to respond to Your inquiries, including to investigate and address Your
          concerns and monitor and improve our Service.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">To
          fulfill or meet the reason You provided the information. For example, if You share Your contact
          information to ask a question about our Service, We will use that personal information to respond to
          Your inquiry. If You provide Your personal information to purchase a product or service, We will use
          that information to process Your payment and facilitate delivery.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">To
          respond to law enforcement requests and as required by applicable law, court order, or governmental
          regulations.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">As
          described to You when collecting Your personal information or as otherwise set forth in the
          CCPA/CPRA.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">For internal administrative and
          auditing purposes.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">To
          detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity,
          including, when necessary, to prosecute those responsible for such activities. 
        </p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Other one-time uses.</p>
      </li>
    </ul>
    <p class="standard">Please note that the examples provided above are illustrative and not intended to be exhaustive.
      For more details on how we use this information, please refer to the "Use of Your Personal Data" section.</p>
    <p class="standard">If We decide to collect additional categories of personal information or use the personal
      information We collected for materially different, unrelated, or incompatible purposes We will update this
      Privacy Policy.</p>
    <h3 class="subtitle">Disclosure of Personal Information</h3>
    <p class="standard">We may use or disclose and may have used or disclosed in the last twelve (12) months the
      following categories of personal information for business or commercial purposes:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">Category A: Identifier.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Category B: Personal information
          categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e).</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Category D: Commercial informatio.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Category F: Internet or other similar
          network activit.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Category G: Geolocation dat.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Category L: Sensitive personal
          informatio.</p>
      </li>
    </ul>
    <p class="standard">Please note that the categories listed above are those defined in the CCPA/CPRA. This does not
      mean that all examples of that category of personal information were in fact disclosed, but reflects our good
      faith belief to the best of our knowledge that some of that information from the applicable category may be and
      may have been disclosed.</p>
    <p class="standard">When We disclose personal information for a business purpose or a commercial purpose, We enter a
      contract that describes the purpose and requires the recipient to both keep that personal information
      confidential and not use it for any purpose except performing the contract.</p>
    <h3 class="subtitle">Share of Personal Information</h3>
    <p class="standard">We may share, and have shared in the last twelve (12) months, Your personal information
      identified in the above categories with the following categories of third parties:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">Service Provider.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Payment processor.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Our affiliate.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Our business partner.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Third party vendors to whom You or Your
          agents authorize Us to disclose Your personal information in connection with products or services We
          provide to Yo.</p>
      </li>
    </ul>
    <h3 class="subtitle">Sale of Personal Information</h3>
    <p class="standard">As defined in the CCPA/CPRA, "sell" and "sale" mean selling, renting, releasing, disclosing,
      disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic
      or other means, a Consumer's personal information by the Business to a third party for valuable consideration.
      This means that We may have received some kind of benefit in return for sharing personal information, but not
      necessarily a monetary benefit.</p>
    <p class="standard">We do not sell personal information as the term sell is commonly understood. We do allow Service
      Providers to use Your personal information for the business purposes described in Our Privacy Policy, for
      activities such as advertising, marketing, and analytics, and these may be deemed a aale under CCPA/CPRA.</p>
    <p class="standard">We may sell and may have sold in the last twelve (12) months the following categories of
      personal information:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">Category A: Identifier.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Category B: Personal information
          categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e).</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Category D: Commercial informatio.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Category F: Internet or other similar
          network activit.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Category G: Geolocation dat.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Category L: Sensitive personal
          informatio.</p>
      </li>
    </ul>
    <p class="standard">Please note that the categories listed above are those defined in the CCPA/CPRA. This does not
      mean that all examples of that category of personal information were in fact sold, but reflects our good faith
      belief to the best of Our knowledge that some of that information from the applicable category may be and may
      have been shared for value in return.</p>
    <h3 class="subtitle">Sale of Personal Information of Minors Under 16 Years of Age</h3>
    <p class="standard">We do not sell the personal information of Consumers We actually know are less than 16 years of
      age, unless We receive affirmative authorization (the "right to opt-in") from either the Consumer who is between
      13 and 16 years of age, or the parent or guardian of a Consumer less than 13 years of age. Consumers who opt-in
      to the sale of personal information may opt-out of future sales at any time. To exercise the right to opt-out,
      You (or Your authorized representative) may submit a request to Us by contacting Us.</p>
    <p class="standard">If You have reason to believe that a child under the age of 13 (or 16) has provided Us with
      personal information, please contact Us with sufficient detail to enable Us to delete that information.</p>
    <h3 class="subtitle">Your Rights under the CCPA/CPRA</h3>
    <p class="standard">The CCPA/CPRA provides California residents with specific rights regarding their personal
      information. If You are a resident of California, You have the following rights:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">The right to
                    notice.</span> You have the right to be notified which categories of Personal Data are being
          collected and the purposes for which the Personal Data is being used.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">The right to
                    know/access.</span> Under CCPA/CPRA, You have the right to request that We disclose information to
          You about Our collection, use, sale, disclosure for business purposes and share of personal information.
          Once We receive and confirm Your request, We will disclose to You:.</p>
      </li>
    </ul>
    <ul>
      <li>
        <p class="P2" style="margin-left:0cm;">The categories of personal information
          We collected about Yo.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">The categories of sources for the
          personal information We collected about Yo.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Our business or commercial purposes for
          collecting or selling that personal informatio.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">The categories of third parties with
          whom We share that personal informatio.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">The specific pieces of personal
          information We collected about Yo.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">If
          we sold Your personal information or disclosed Your personal information for a business purpose, We will
          disclose to You:.</p>
      </li>
    </ul>
    <ul>
      <li>
        <p class="P3" style="margin-left:0cm;">The categories of personal information
          categories sol.</p>
      </li>
      <li>
        <p class="P3" style="margin-left:0cm;">The categories of personal information
          categories disclose.</p>
      </li>
    </ul>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">The right to say no to
                    the sale or sharing of Personal Data (opt-out).</span> You have the right to direct Us to not sell
          Your personal information. To submit an opt-out request, please see the "Do Not Sell My Personal
          Information" section or contact Us.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">The right to correct
                    Personal Data.</span> You have the right to correct or rectify any any inaccurate personal
          information about You that We collected. Once We receive and confirm Your request, We will use
          commercially reasonable efforts to correct (and direct our Service Providers to correct) Your personal
          information, unless an exception applies.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">The right to limit use
                    and disclosure of sensitive Personal Data.</span> You have the right to request to limit the use or
          disclosure of certain sensitive personal information We collected about You, unless an exception
          applies. To submit, please see the "Limit the Use or Disclosure of My Sensitive Personal Information"
          section or contact Us.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">The right to delete
                    Personal Data.</span> You have the right to request the deletion of Your Personal Data under certain
          circumstances, subject to certain exceptions. Once We receive and confirm Your request, We will delete
          (and direct Our Service Providers to delete) Your personal information from our records, unless an
          exception applies. We may deny Your deletion request if retaining the information is necessary for Us or
          Our Service Providers to:.</p>
      </li>
    </ul>
    <ul>
      <li>
        <p class="P2" style="margin-left:0cm;">Complete the transaction for which We
          collected the personal information, provide a good or service that You requested, take actions
          reasonably anticipated within the context of our ongoing business relationship with You, or otherwise
          perform our contract with You.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Detect security incidents, protect
          against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such
          activities.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Debug products to identify and repair
          errors that impair existing intended functionality.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Exercise free speech, ensure the right
          of another consumer to exercise their free speech rights, or exercise another right provided for by
          law.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Comply with the California Electronic
          Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Engage in public or peer-reviewed
          scientific, historical, or statistical research in the public interest that adheres to all other
          applicable ethics and privacy laws, when the information's deletion may likely render impossible or
          seriously impair the research's achievement, if You previously provided informed consent.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Enable solely internal uses that are
          reasonably aligned with consumer expectations based on Your relationship with Us.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Comply with a legal obligation.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Make other internal and lawful uses of
          that information that are compatible with the context in which You provided it.</p>
      </li>
    </ul>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;"><span class="T2">The right not to be
                    discriminated against.</span> You have the right not to be discriminated against for exercising any
          of Your consumer's rights, including by:.</p>
      </li>
    </ul>
    <ul>
      <li>
        <p class="P2" style="margin-left:0cm;">Denying goods or services to Yo.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Charging different prices or rates for
          goods or services, including the use of discounts or other benefits or imposing penaltie.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Providing a different level or quality
          of goods or services to Yo.</p>
      </li>
      <li>
        <p class="P2" style="margin-left:0cm;">Suggesting that You will receive a
          different price or rate for goods or services or a different level or quality of goods or service.</p>
      </li>
    </ul>
    <h3 class="subtitle">Exercising Your CCPA/CPRA Data Protection Rights</h3>
    <p class="standard">Please see the "Do Not Sell My Personal Information" section and "Limit the Use or Disclosure of
      My Sensitive Personal Information" section for more information on how to opt out and limit the use of sensitive
      information collected.</p>
    <p class="standard">Additionally, in order to exercise any of Your rights under the CCPA/CPRA, and if You are a
      California resident, You can contact Us:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">By
          email: support@pyjam.co.</p>
      </li>
    </ul>
    <p class="standard">Only You, or a person registered with the California Secretary of State that You authorize to
      act on Your behalf, may make a verifiable request related to Your personal information.</p>
    <p class="standard">Your request to Us must:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">Provide sufficient information that
          allows Us to reasonably verify You are the person about whom We collected personal information or an
          authorized representativ.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Describe Your request with sufficient
          detail that allows Us to properly understand, evaluate, and respond to i.</p>
      </li>
    </ul>
    <p class="standard">We cannot respond to Your request or provide You with the required information if We cannot:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">Verify Your identity or authority to
          make the reques.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">And confirm that the personal
          information relates to Yo.</p>
      </li>
    </ul>
    <p class="standard">We will disclose and deliver the required information free of charge within 45 days of receiving
      Your verifiable request. The time period to provide the required information may be extended once by an
      additional 45 days when reasonably necessary and with prior notice.</p>
    <p class="standard">Any disclosures We provide will only cover the 12-month period preceding the verifiable
      request's receipt.</p>
    <p class="standard">For data portability requests, We will select a format to provide Your personal information that
      is readily usable and should allow You to transmit the information from one entity to another entity without
      hindrance.</p>
    <h3 class="subtitle">Do Not Sell My Personal Information</h3>
    <p class="standard">As defined in the CCPA/CPRA, "sell" and "sale" mean selling, renting, releasing, disclosing,
      disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic
      or other means, a Consumer's personal information by the Business to a third party for valuable consideration.
      This means that We may have received some kind of benefit in return for sharing personal information, but not
      necessarily a monetary benefit.</p>
    <p class="standard">We do not sell personal information as the term sell is commonly understood. We do allow Service
      Providers to use Your personal information for the business purposes described in Our Privacy Policy, for
      activities such as advertising, marketing, and analytics, and these may be deemed a sale under CCPA/CPRA.</p>
    <p class="standard">You have the right to opt-out of the sale of Your personal information. Once We receive and
      confirm a verifiable consumer request from You, we will stop selling Your personal information. To exercise Your
      right to opt-out, please contact Us.</p>
    <p class="standard">The Service Providers we partner with (for example, our analytics or advertising partners) may
      use technology on the Service that sells personal information as defined by the CCPA/CPRA law. If you wish to
      opt out of the use of Your personal information for interest-based advertising purposes and these potential
      sales as defined under CCPA/CPRA law, you may do so by following the instructions below.</p>
    <p class="standard">Please note that any opt out is specific to the browser You use. You may need to opt out on
      every browser that You use.</p>
    <h4 class="Heading_20_4">Website</h4>
    <p class="standard">If applicable, click "Privacy Preferences", "Update Privacy Preferences" or "Do Not Sell My
      Personal Information" buttons listed on the Service to review your privacy preferences and opt out of cookies
      and other technologies that We may use. Please note that You will need to opt out from each browser that You use
      to access the Service.</p>
    <p class="standard">Additionally, You can opt out of receiving ads that are personalized as served by our Service
      Providers by following our instructions presented on the Service:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">The NAI's opt-out platform: <a
          href="http://www.networkadvertising.org/choices/" class="external-link"><span
          class="external-link-text">http://www.networkadvertising.org/choices/</span></a>.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">The EDAA's opt-out platform <a
          href="http://www.youronlinechoices.com/" class="external-link"><span
          class="external-link-text">http://www.youronlinechoices.com/</span></a> 
        </p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">The DAA's opt-out platform: <a
          href="http://optout.aboutads.info/?c=2&amp;lang=EN" class="external-link"><span
          class="external-link-text">http://optout.aboutads.info/?c=2&amp;lang=EN</span></a>.</p>
      </li>
    </ul>
    <p class="standard">The opt out will place a cookie on Your computer that is unique to the browser You use to opt
      out. If you change browsers or delete the cookies saved by your browser, You will need to opt out again.</p>
    <h4 class="Heading_20_4">Mobile Devices</h4>
    <p class="standard">Your mobile device may give You the ability to opt out of the use of information about the apps
      You use in order to serve You ads that are targeted to Your interests:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">"Opt out of Interest-Based Ads" or "Opt
          out of Ads Personalization" on Android device.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">"Limit Ad Tracking" on iOS device.</p>
      </li>
    </ul>
    <p class="standard">You can also stop the collection of location information from Your mobile device by changing the
      preferences on Your mobile device.</p>
    <h3 class="subtitle"><a
      id="a__Limit_the_Use_or_Disclosure_of_My_Sensitive_Personal_Information"></a>Limit the Use or
      Disclosure of My Sensitive Personal Information</h3>
    <p class="standard">If You are a California resident, You have the right to limit the use and disclosure of Your
      sensitive personal information to that use which is necessary to perform the services or provide the goods
      reasonably expected by an average Consumer who requests such services or goods.</p>
    <p class="standard">We collect, use and disclose sensitive personal information in ways that are necessary to
      provide the Service. For more information on how We use Your personal information, please see the "Use of Your
      Personal Data" section or contact us.</p>
    <h2>"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)</h2>
    <p class="standard">Our Service does not respond to Do Not Track signals.</p>
    <p class="standard">However, some third party websites do keep track of Your browsing activities. If You are
      visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want
      to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.
    </p>
    <h2>Your California Privacy Rights (California's Shine the Light law)</h2>
    <p class="standard">Under California Civil Code Section 1798 (California's Shine the Light law), California
      residents with an established business relationship with us can request information once a year about sharing
      their Personal Data with third parties for the third parties' direct marketing purposes.</p>
    <p class="standard">If you'd like to request more information under the California Shine the Light law, and if You
      are a California resident, You can contact Us using the contact information provided below.</p>
    <h2>California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</h2>
    <p class="standard">California Business and Professions Code Section 22581 allows California residents under the age
      of 18 who are registered users of online sites, services or applications to request and obtain removal of
      content or information they have publicly posted.</p>
    <p class="standard">To request removal of such data, and if You are a California resident, You can contact Us using
      the contact information provided below, and include the email address associated with Your account.</p>
    <p class="standard">Be aware that Your request does not guarantee complete or comprehensive removal of content or
      information posted online and that the law may not permit or require removal in certain circumstances.</p>
    <h2>Children's Privacy</h2>
    <p class="standard">The Service may contain content appropriate for children under the age of 13. As a parent, you
      should know that through the Service children under the age of 13 may participate in activities that involve the
      collection or use of personal information. We use reasonable efforts to ensure that before we collect any
      personal information from a child, the child's parent receives notice of and consents to our personal
      information practices.</p>
    <p class="standard">We also may limit how We collect, use, and store some of the information of Users between 13 and
      18 years old. In some cases, this means We will be unable to provide certain functionality of the Service to
      these Users. If We need to rely on consent as a legal basis for processing Your information and Your country
      requires consent from a parent, We may require Your parent's consent before We collect and use that information.
    </p>
    <p class="standard">We may ask a User to verify its date of birth before collecting any personal information from
      them. If the User is under the age of 13, the Service will be either blocked or redirected to a parental consent
      process.</p>
    <h3 class="subtitle">Information
      Collected from Children Under the Age of 13</h3>
    <p class="standard">The Company may collect and store persistent identifiers such as cookies or IP addresses from
      Children without parental consent for the purpose of supporting the internal operation of the Service.</p>
    <p class="standard">We may collect and store other personal information about children if this information is
      submitted by a child with prior parent consent or by the parent or guardian of the child.</p>
    <p class="standard">The Company may collect and store the following types of personal information about a child when
      submitted by a child with prior parental consent or by the parent or guardian of the child:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">First and/or last nam.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Date of birth 
        </p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Gende.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Grade level 
        </p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Email address 
        </p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Telephone numbe.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Parent's or guardian's nam.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Parent's or guardian's email
          addres.</p>
      </li>
    </ul>
    <p class="standard">For further details on the information We might collect, You can refer to the "Types of Data
      Collected" section of this Privacy Policy. We follow our standard Privacy Policy for the disclosure of personal
      information collected from and about children.</p>
    <h3 class="subtitle">Parental Access</h3>
    <p class="standard">A parent who has already given the Company permission to collect and use his child personal
      information can, at any time:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">Review, correct or delete the child's
          personal informatio.</p>
      </li>
      <li>
        <p class="P4" style="margin-left:0cm;">Discontinue further collection or use
          of the child's personal informatio.</p>
      </li>
    </ul>
    <p class="standard">To make such a request, You can write to Us using the contact information provided in this
      Privacy Policy.</p>
    <h2>Links to Other Websites</h2>
    <p class="standard">Our Service may contain links to other websites that are not operated by Us. If You click on a
      third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy
      Policy of every site You visit.</p>
    <p class="standard">We have no control over and assume no responsibility for the content, privacy policies or
      practices of any third party sites or services.</p>
    <h2>Changes to this Privacy Policy</h2>
    <p class="standard">We may update Our Privacy Policy from time to time. We will notify You of any changes by posting
      the new Privacy Policy on this page.</p>
    <p class="standard">We will let You know via email and/or a prominent notice on Our Service, prior to the change
      becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
    <p class="standard">You are advised to review this Privacy Policy periodically for any changes. Changes to this
      Privacy Policy are effective when they are posted on this page.</p>
    <h2>Contact Us</h2>
    <p class="standard">If you have any questions about this Privacy Policy, You can contact us:</p>
    <ul>
      <li>
        <p class="P4" style="margin-left:0cm;">By email: <strong>support@pyjam.co</strong>.</p>
      </li>
    </ul>
  </div>
</ion-content>
