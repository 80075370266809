import {
  Component,
  OnInit
} from '@angular/core';
import {
  NavigationService
} from "../../../services/navigation.service";
import {
  Client,
  FileResponse,
  ReplyResponse
} from "../../../api-clients/pyjam/client";
import {
  HttpErrorHandlerService
} from "../../../services/http-error-handler.service";
import {
  AuthService
} from "../../../auth/auth.service";
import {
  ActivatedRoute,
  Navigation,
  Router
} from "@angular/router";
import {
  ReplyStatus
} from "../../reply-status.enum";
import {
  LoadingService
} from "../../../services/loading.service";
import {
  ActivatedRouteFixService
} from "../../../services/activated-route-fix.service";
import {
  ToastService
} from "../../../services/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {ActionSheetController} from "@ionic/angular";
import {ReportService} from "../../../services/report.service";
import {ReportType} from "../../../ui-components/report-content-popup/report-content-popup.component";

@Component({
  selector: 'show-result',
  templateUrl: './show-task-result.component.html',
  styleUrls: ['./show-task-result.component.scss'],
})
export class ShowTaskResultComponent implements OnInit {
  private replyId: number;
  public files: FileResponse[] = [];
  public description: string;
  public isTaskOwner: boolean;
  public reply: ReplyResponse;
  private taskResultId: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private activatedRouteFixService: ActivatedRouteFixService,
    private router: Router,
    private navigationService: NavigationService,
    private client: Client,
    private httpErrorHandlerService: HttpErrorHandlerService,
    public authService: AuthService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private actionSheetController: ActionSheetController,
    private reportService: ReportService,
  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.loadingService.start().then(() => {
      this.replyId = this.activatedRoute.snapshot.params?.id;
      this.client.replyGet(this.replyId, false).toPromise()
        .then((res) => {
          this.reply = res.data;
          this.client.taskResultShowByTaskId(res.data.task_id).toPromise()
            .then((rspns) => {
              this.description = rspns.data.description;
              this.files = rspns.data.files;
              this.isTaskOwner = rspns.data.user_id != this.authService.parsedToken.userId;
              this.taskResultId = rspns.data.id;
            })
            .catch(async ex => {
              // await this.httpErrorHandlerService.handleHttpException(ex);
            })
            .finally(async () => {
              await this.loadingService.stop();
            });
        });
    })
  }

  public get ReplyStatus(): typeof ReplyStatus {
    return ReplyStatus;
  }

  openFile(file: FileResponse) {
    window.open(file.url, "_blank");
  }

  async onReplyAcceptClick() {
    this.loadingService.start()
      .then(() => {
        this.client.replyAccept(this.replyId).toPromise()
          .then(async () => {
            await this.router.navigate(['../rate-result'], {relativeTo: this.activatedRouteFixService.getActivatedRoute(this.activatedRoute)});
          })
          .catch(async ex => {
            let parsedException = JSON.parse(ex.response);
            let message = parsedException.message ?? parsedException.error;
            if (message == 'transfers_not_allowed') {
              await this.toastService.warning(this.translateService.instant('task.details.onboardingErrorsReset'));
            } else {
              // await this.httpErrorHandlerService.handleHttpException(ex);
            }
          })
      })
      .finally(async () => {
        await this.loadingService.stop();
      });
  }

  async onRateButtonClick() {
    await this.router.navigate(['../rate-result'], {relativeTo: this.activatedRouteFixService.getActivatedRoute(this.activatedRoute)});
  }

  async onReplyDisputeClick() {
    await this.router.navigate(['/reply/' + this.replyId + '/dispute-description']);
  }

  async onReplySendResultClick() {
    await this.router.navigate(['/reply/' + this.replyId + '/task-result/files']);
  }

  async onReplyEditResultClick() {
    await this.router.navigate(['/reply/' + this.replyId + '/edit-task-result']);
  }

  openMenu() {
    this.actionSheetController.create({
      buttons: [
        {
          text: this.translateService.instant('report.type.' + ReportType.TASK_RESULT),
          handler: () => {
            this.reportService.openReportModal(ReportType.TASK_RESULT, this.taskResultId).then();
          }
        }
      ],
    }).then((actionSheet) => {
      actionSheet.present().then();
    });
  }
}
