import { Injectable } from '@angular/core';
import { GetResult, Preferences } from '@capacitor/preferences';

// class TokenInfo {
//   constructor(public token: string, public refreshToken: string) {
//   }
// }

@Injectable()
export class TokenStoreService {
  private readonly KEY: string = 'token';
  private readonly KEY2: string = 'refresh_token';

  constructor() {
  }

  // async getTokenInfo(): Promise<TokenInfo | null> {
  //   const tokenResult: GetResult = await Preferences.get({key: this.KEY});
  //   const refreshTokenResult: GetResult = await Preferences.get({key: this.KEY2});
  //
  //   if (tokenResult.value && refreshTokenResult.value) {
  //     return new TokenInfo(tokenResult.value, refreshTokenResult.value);
  //   }
  //
  //   return null;
  // }

  public async getToken(): Promise<string | null> {
    const tokenResult: GetResult = await Preferences.get({key: this.KEY});
    return tokenResult.value ?? null;
  }

  public async getRefreshToken(): Promise<string | null> {
    const refreshTokenResult: GetResult = await Preferences.get({key: this.KEY2});
    return refreshTokenResult.value ?? null;
  }

  public async setTokens(token: string, refreshToken: string): Promise<void> {
    await Preferences.set({key: this.KEY, value: token});
    await Preferences.set({key: this.KEY2, value: refreshToken});
  }

  public async removeTokens(): Promise<void> {
    await Preferences.remove({key: this.KEY});
    await Preferences.remove({key: this.KEY2});
  }
}
