<ion-content [fullscreen]="true">
  <div class="ion-text-center container">
    <form [formGroup]="resetForm" (ngSubmit)="reset()">
      <strong>{{ 'recovery.password' | translate }}</strong>
      <ion-input [placeholder]="'reset.enterCodePlaceholder' | translate"
                 formControlName="code"></ion-input>
      <div *ngIf="resetForm.get('code').touched &&
                  resetForm.get('code').invalid &&
                  resetForm.get('code').errors.required">
        {{ 'reset.enterCode' | translate }}
      </div>
      <ion-input [placeholder]="'reset.enterPasswordPlaceholder' | translate"
                 type="password"
                 formControlName="password"></ion-input>
      <div *ngIf="resetForm.get('password').touched &&
                  resetForm.get('password').invalid &&
                  resetForm.get('password').errors.required">
        {{ 'reset.enterPassword' | translate }}
      </div>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button color="light" expand="block" (click)="reset()">
              {{ 'reset.resetButton' | translate }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>
</ion-content>
