<ion-content>
  <div class="container" *ngIf="status === 'success'">
    <ion-icon color="success" name="checkmark-outline"></ion-icon>
    <h1>{{ 'payment.success' | translate }}</h1>
    <p>{{ 'payment.successPay' | translate }}</p>
  </div>

  <div class="container" *ngIf="status === 'fail'">
    <ion-icon color="danger" name="close-outline"></ion-icon>
    <h1>{{ 'payment.errorTitle' | translate }}</h1>
    <p>{{ 'payment.errorText' | translate }}</p>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <ion-button (click)="goToReply()"
              class="button-bottom"
              color="dark"
              expand="block">
    {{ 'payment.back' | translate }}
  </ion-button>
</ion-footer>
