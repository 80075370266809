import {Injectable} from '@angular/core';
import {Platform} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  public isAndroid: boolean = false;
  public isIos: boolean = false;
  public isMobile: boolean = false;
  public isDesktop: boolean = false;
  public isMobileWeb: boolean = false;
  public isCordova: boolean = false;
  public isCapacitor: boolean = false;
  public isPWA: boolean = false;
  public isDevice: boolean = false;
  public isAndroidApp: boolean = false;
  public isIosApp: boolean = false;

  constructor(private platform: Platform) {

    // Проверка платформы:

    this.isAndroid = this.platform.is('android'); //Возвращает true, если приложение запущено на Android устройстве.
    // console.log('isAndroid: ', this.isAndroid);

    this.isIos = this.platform.is('ios'); // Возвращает true, если приложение запущено на iOS устройстве.
    // console.log('isIos: ', this.isIos);

    // Проверка режима запуска:

    this.isMobile = this.platform.is('mobile'); // Возвращает true, если приложение запущено на мобильном устройстве.
    // console.log('isMobile: ', this.isMobile);

    this.isDesktop = this.platform.is('desktop'); // Возвращает true, если приложение запущено на настольном компьютере.
    // console.log('isDesktop: ', this.isDesktop);

    this.isMobileWeb = this.platform.is('mobileweb'); // Возвращает true, если приложение запущено в браузере на мобильном устройстве.
    // console.log('isMobileWeb: ', this.isMobileWeb);

    this.isCordova = this.platform.is('cordova'); // Возвращает true, если приложение запущено на Cordova.
    // console.log('isCordova: ', this.isCordova);

    this.isCapacitor = this.platform.is('capacitor'); // Возвращает true, если приложение запущено на Capacitor.
    // console.log('isCapacitor: ', this.isCapacitor);

    // Проверка платформы выполнения:

    this.isPWA = this.platform.is('pwa'); // Возвращает true, если приложение запущено как PWA (Progressive Web App).
    // console.log('isPWA: ', this.isPWA);

    this.isDevice = this.platform.is('hybrid'); // Возвращает true, если приложение запущено в гибридном режиме (Cordova/PhoneGap или Capacitor).
    // console.log('isDevice: ', this.isDevice);

    this.isAndroidApp = this.platform.is('android') && !this.platform.is('mobileweb'); // Возвращает true, если приложение запущено на Android устройстве и в браузере.
    // console.log('isAndroidApp: ', this.isAndroidApp);

    this.isIosApp = this.platform.is('ios') && !this.platform.is('mobileweb'); // Возвращает true, если приложение запущено на Android устройстве и в браузере.
    // console.log('isIosApp: ', this.isIosApp);

  }

}
