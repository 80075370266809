import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import {
  Client,
  LangResponse,
  OnboardingCountyCodes
} from "../../api-clients/pyjam/client";
import {
  IonInput,
  IonModal
} from "@ionic/angular";
import {
  LoadingService
} from "../../services/loading.service";
import { environment } from 'src/environments/environment';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-language-choose',
  templateUrl: './language-choose.component.html',
  styleUrls: ['./language-choose.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LanguageChooseComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: LanguageChooseComponent
    }
  ]
})
export class LanguageChooseComponent implements ControlValueAccessor, Validator, OnInit {
  @ViewChild(IonModal) modal: IonModal;

  langs: LangResponse[];
  activeLangs: string[];
  language = new LanguageControlValue();

  onChange = (lang) => {
  };
  onTouched = () => {
  };

  touched = false;

  constructor(
    private loadingService: LoadingService,
    private client: Client,
    private translate: TranslateService,
  ) {
    this.activeLangs = environment.activeLanguages;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return (control.value?.lang) ? null : {
        empty: true
    };
  }

  ngOnInit(): void {
    this.loadingService.start()
      .then(async () => {
        await this.client.lang()
          .toPromise()
          .then((langs: LangResponse[]) => {
            this.langs = langs;
            this.languageChanged(this.translate.currentLang);
          })
          .finally(async () => {
            await this.loadingService.stop();
          })
      });
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(lang: any): void {
  }

  languageChanged(lang) {
    this.language.lang = lang.detail?.value || lang;
    this.language.response = this.langs.find((l) => l.locale == this.language.lang);
    this.language.name = this.language.response.name;
    this.onChange(this.language);
  }
}

export class LanguageControlValue {
  lang: string;
  name: string;
  response: LangResponse;

  toString() {
    return JSON.stringify(this.response);
  }
}
