<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons
      slot="start">
      <ion-button
        (click)="close()">
        <ion-icon
          slot="icon-only"
          name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ 'reply.replyInfo.edit' | translate }}
    </ion-title>
    <ion-buttons
      slot="end">
      <app-save-button
        (click)="finishEditFilesInfo()">
      </app-save-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-bottom ion-padding-start ion-padding-end">
  <div class="images-sliders" *ngIf="files.length > 0">
    <div class="slider">
      <div class="swiper-container customSwiper">
        <div class="swiper-wrapper">
          <div  class="swiper-slide" *ngFor="let file of files; let i = index">
            <div class="content">
              <div class="file">
                  <div class="icon">
                    <img *ngIf="!!file.src && file.src != ''" class="icon-file" [src]="file.src" alt="">
                    <img *ngIf="!file.src || file.src == ''" class="icon-file" src="{{ file.name | iconForFile }}" alt="">
                  </div>
                  <div class="info">
                    <div class="name">{{file.name}}</div>
                    <div class="size">{{file.size | sizeFile}}</div>
                  </div>
              </div>
              <div class="file-info">
                <div class="description-textarea">
                  <ion-textarea class="custom-ion-textarea ion-margin"
                                #description
                                rows="15"
                                placeholder="{{ 'reply.replyInfo.fileDescPlaceholder' | translate }}"
                                [value]="file.description"
                                (ionChange)="saveDescription(description, file)">
                  </ion-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>

