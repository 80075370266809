import {Subscription} from "rxjs";

export class SubscriptionsBag {
  private _subs: Subscription[] = [];

  public set sub(value: Subscription) {
    this._subs.push(value);
  }

  public unsubscribeAll(): void {
    for (let s of this._subs) {
      s.unsubscribe();
      s = null;
    }

    this._subs = [];
  }
}
