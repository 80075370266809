<ion-modal (didDismiss)="closeModal()" [isOpen]="selectedFilesForSlider.length > 0">
  <ng-template>
    <ion-header class="black">
      <ion-toolbar>
        <div>
          <div class="title-slot">
            <div class="title">
              <ng-container *ngIf="profile">
                <div class="avatar">
                  <app-avatar
                    class="ion-margin-end" slot="start"
                    style="width: 40px; height: 40px"
                    [showOriginalByClick]="false"
                    [name]="profile.name + ' ' + profile.surname"
                    typeAvatar='with-light'
                    sizeLight="super-big"
                    [avatarFile]="profile.avatarFile">
                  </app-avatar>
                </div>
                <div class="info">
                  <div>
                    <h3>{{profile.name}} {{profile.surname}}</h3>
                  </div>
                </div>
              </ng-container>
            </div>
            <ion-buttons style="flex-shrink: 0; flex-grow: 0">
              <ion-button (click)="downloadFile()" *ngIf="isAcceptEditing">
                <ion-icon class="menu" name="arrow-redo"></ion-icon>
              </ion-button>
              <ion-button (click)="openMenu()">
                <ion-icon class="menu" name="ellipsis-horizontal"></ion-icon>
              </ion-button>
              <ion-button (click)="selectedFilesForSlider = []">
                <ion-icon class="menu" name="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding-bottom ion-padding-end ion-padding-start">
      <div class="images-sliders"
           *ngIf="selectedFilesForSlider.length > 0"
           (click)="selectedFilesForSlider = []">
        <div class="slider">
          <div #swiperContainer class="swiperContainer customSwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" *ngFor="let file of selectedFilesForSlider; let i = index">
                <div class="content swiper-zoom-container">
                  <div>
                    <img *ngIf="FileTypes.TYPE_IMAGE === file.type.id"
                         (click)="$event.stopImmediatePropagation();"
                         src="{{ file.resizes ? file.resizes[2].url : file.url }}"
                         class="file-img"/>
                    <video *ngIf="FileTypes.TYPE_VIDEO === file.type.id"
                           (click)="$event.stopImmediatePropagation();"
                           [autoplay]="false"
                           [loop]="false"
                           class="video-image-set"
                           controls>
                      <source src="{{file.url}}">
                    </video>
                    <div class="audio" *ngIf="FileTypes.TYPE_AUDIO === file.type.id">
                      <audio
                        (click)="$event.stopImmediatePropagation();"
                        src="{{file.url}}"
                        [autoplay]="false"
                        [loop]="false"
                        class="audio-image-set"
                        controls>
                      </audio>
                      <div class="info">
                        <div class="name">{{file.name}}</div>
                        <div class="size">{{file.size | sizeFile}}</div>
                      </div>
                    </div>

                    <div class="file"
                         *ngIf="FileTypes.TYPE_AUDIO !== file.type.id
                            && FileTypes.TYPE_VIDEO !== file.type.id
                            && FileTypes.TYPE_IMAGE !== file.type.id"
                         (click)="$event.stopImmediatePropagation();">
                      <div class="icon">
                        <img class="icon-file" src="{{file.name | iconForFile}}" alt="">
                      </div>
                      <div class="info">
                        <div class="name">{{file.name}}</div>
                        <div class="size">{{file.size | sizeFile}}</div>
                      </div>
                    </div>

                    <div class="description" *ngIf="file.description">
                      <div>{{file.description}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

