<ion-content [fullscreen]="true">
  <div class="ion-text-center container">
    <form [formGroup]="recoveryForm" (ngSubmit)="recovery()">
      <strong>
        {{ 'recovery.password' | translate }}
      </strong>
      <ion-input [placeholder]="'recovery.enterNumberPlaceholder' | translate"
                 type="tel"
                 formControlName="phone"></ion-input>
      <div *ngIf="recoveryForm.get('phone').touched &&
                  recoveryForm.get('phone').invalid">
        <div *ngIf="recoveryForm.get('phone').errors.required">
          {{ 'recovery.errors.number' | translate }}
        </div>
        <div *ngIf="recoveryForm.get('phone').errors.pattern">
          {{ 'recovery.errors.numberFormat' | translate }}
        </div>
      </div>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button color="light" expand="block" (click)="recovery()">
              {{ 'recovery.sendButton' | translate }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>
</ion-content>
