<ion-img *ngFor="let img of images; index as i"
         [src]="img.url"
         (click)="imageSlider.showImages(images, i)">
</ion-img>

<!--<ng-container *ngFor="let img of variableImages; index as i">-->
<!--  <ion-img *ngIf="img.imageFileName" [src]="img.imageFileName | fileUrl | thumbnailUrl"-->
<!--           (click)="imageSlider.showImages(files, i)"></ion-img>-->
<!--  <ion-img *ngIf="img.imageBase64" [src]="img.imageBase64" (click)="onImageClick(i)"></ion-img>-->
<!--</ng-container>-->

<ion-buttons class="add-button" *ngIf="allowAdding"
             style="display: flex; flex-direction: column; align-items: center; justify-content: center">
  <ion-button (click)="addClicked.emit()">
    <ion-icon name="add-circle-outline" size="large" slot="icon-only"></ion-icon>
  </ion-button>
</ion-buttons>

<app-image-slider #imageSlider></app-image-slider>
