<div class="language-selection">
  <div class="country-input" id="open-lang-modal">
    <div class="language-output" [class.placeholder]="!language?.lang">{{ language?.lang ? language.name : 'profile.appLanguage.title' | translate }}</div>
    <ion-icon src="/assets/icon/dropdown-arrow.svg" class="dropdown-arrow"></ion-icon>
  </div>

  <ion-modal #modal trigger="open-lang-modal">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ 'profile.appLanguage.title' | translate }}</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="modal.dismiss()">{{'sign.countryCodeClose' | translate}}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list>
          <ion-radio-group allow-empty-selection="false" name="radio-group" [value]="" (ionChange)="languageChanged($event)" #radioGroup>
            <ion-item *ngFor="let lang of langs">
              <ion-label>
                <h2>{{ lang.name }}</h2>
              </ion-label>
              <ion-radio slot="end" [value]="lang.locale" [disabled]="activeLangs.indexOf(lang.locale) === -1"></ion-radio>
            </ion-item>
          </ion-radio-group>

        </ion-list>
      </ion-content>
    </ng-template>
  </ion-modal>
</div>
